.contact-blocks {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 5rem 3rem;
  padding: 3em 0;
  width: 94%;
  max-width: 1200px;
  margin: 0 auto;
  
  @include MQ(md) {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }
}

.contact-block {

  form {
    input, textarea {
      display: block;
      width: 100%;
      padding-left: 0.5rem;
    }
  }

  .formPane {
    width: 100%;
    padding-top: 0;
    
    form button {
      width: 10rem;
      text-transform: uppercase;
      font-family: "Visby";
      font-weight: 300;
      margin: 0;
    }

    .buttonGroup {
      padding: 10px;
    }
  }

  h3 {
    position: relative;
    display: inline-block;
    &:after {
      content: '';
      position: absolute;
      bottom: 5px;
      left: 10px;
      height: 2px;
      // width: 275px;
      width: 100%;
      background: $blue;
    }
  }

  .contact-subscribe-form {
    padding: 0 !important;
  }

  #mc_embed_signup form {
    text-align: left;
  }

  #mc_embed_signup_scroll input[type="email"], .clear {
    // width: 100%;
    display: inline-block;
    margin-top: 1em;
  }
  
  #mc_embed_signup_scroll input[type="email"] {
    background-color: lighten($light-gray, 10%);
    width: calc(100% - 200px);
    min-width: 200px;
  }
}

.map {
  height: 420px;

  iframe {
    height: 100%;
    width: 100%;
  }
}