section.pagePreview {
  width: 100%;
  height: 100vh;
  background: url("") no-repeat center center fixed;
  @include bg-size(cover);
  padding: $gutter_width;

  h4,
  p,
  a {
    color: $white;
    padding: $gutter_width/2 0px;
  }

  a {
    @include display-flex;
    @include align-items(center);
    @include transition(opacity 300ms ease);
    opacity: .75;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    // font-size: .7rem;

    img {
      padding-left: $gutter_width/2;
      width: 35px;
      opacity: .75;
      position: relative;
      @include transition(all 350ms ease);
      left: 0;
    }

    &:hover {
      opacity: 1;

      img {
        opacity: 1;
        left: $gutter_width/2;
      }
    }
  }

  h4 {
    width: 50%;
    border-bottom: 1px solid $white;
    padding: 0px;
    padding-bottom: $gutter_width/4;
  }

  p {
    // line-height: 1.7;
  }

  div.panel {
    @include display-flex;
    position: relative;
    height: 100%;
    width: 100%;

    @include MQ(l) {
      width: 50%;

    }
  }

  div.colorOverlay {
    opacity: .75;
    height: 100%;
    width: 100%;
    position: absolute;
    @include center(xy);
  }

  div.copy {
    z-index: 50;
    position: absolute;
    top: 55%;
    width: 100%;
    padding: $gutter_width;

    @include MQ(sm) {
      width: 50%;
    }

    @include MQ(md) {
      width: percentage(2/5);
      right: $gutter_width;
      padding: 0px;
    }
  }
}


section.pageIntro {
  position: relative;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);

  div.introCopy {
    z-index: 100;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0px $gutter_width;
    height: 55vh;

    @include MQ(sm) {
      max-height: 400px;
    }

    @include MQ(md) {
      max-height: 300px;
      height: 40vh;
    }
  }

  a.downArrow {
    position: absolute;
    top: $gutter_width;
    right: $gutter_width*2;
  }

  div.introColor {
    width: 100%;
    height: 100%;
  }

  div.copy {
    position: absolute;
    @include center(xy);
    color: $white;
    width: 100%;
    padding: 0px $gutter_width*2;

    @include MQ(md) {
      width: auto;
      padding: 0px;
    }
  }

}

.breadcrumbs__container {
  position: relative;
}

.breadcrumbs {
  // position: absolute;
  // top: $gutter_width;
  // left: $gutter_width*2;

  ol {
    list-style: none;
  }

  li {
    display: inline-block;

    &:after {
      content: '>';
      position: absolute;
      right: 0.1em;
      top: 0.3em;
      line-height: 1;
    }

    &:last-of-type {
      font-weight: normal;
      opacity: 1;

      &:after {
        content: '';
      }
    }
  }

  // span {
  //   color: $white;
  // }

  a {
    position: relative;
    // margin-right: 1em;
    font-weight: 400;
    text-decoration: underline;

  }

}

section.longFormText {
  padding: 0px $gutter_width;
  @include display-flex;

  div.copy {
    margin: 0 auto;
    @include display-flex;
    @include flex-direction(column);
    width: 100%;
    padding: $gutter_width 0px;

    @include MQ(md) {
      @include flex-direction(row);
      width: percentage(5/6);
      // padding: $gutter_width*4 0px;
    }
  }

  div.bodyText {
    @include MQ(md) {
      padding-right: 80px;
    }
  }

  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
  }

  // h2 {
  //   color: $brand-yellow;
  // }

  p,
  h2 {
    padding: $gutter_width/2;
  }

  p a {
    color: $blue;
  }

  li {
    padding-left: $gutter_width*2;

    &:before {
      content: "\f061";
      font-family: FontAwesome;
      display: inline-block;
      margin-left: -$gutter_width*1.2;
      width: $gutter_width*1.2;
    }
  }

  &.dark {
    background: #4A4D4E;

    div.componentBackground {
      background-color: rgba(125, 125, 125, 0.9);
    }

    h2,
    p,
    li {
      color: $white;
    }
  }
}

div.sideNav {

  h5,
  p {
    padding: 0px;
    color: $white;
  }

  h5 {
    text-transform: uppercase;
  }

  a {
    display: block;
    padding: $gutter_width 0px;
    border-bottom: 1px solid $white;
    opacity: .75;
    @include transition(opacity 300ms ease);

    i {
      position: relative;
      left: 0px;
      @include transition(left 300ms ease);
    }

    &:hover {
      opacity: 1;

      i {
        left: $gutter_width/2;
      }
    }

    &:last-of-type {
      border: 0px;
    }
  }

  @include MQ(md) {
    width: percentage(2/5);
    padding-top: 60px;

  }
}

section.heroCenter {
  @extend %hero;

  div.copy {
    @include center(xy);
    color: $white;
    padding: $gutter_width;

    p {
      -webkit-font-smoothing: auto;
    }

    @include MQ(md) {
      padding: $gutter_width*2;
    }
  }

  a {
    color: $brand-yellow;
    border-bottom: 2px solid rgba(250, 193, 20, 0.9);

    &.cappedLink {
      border: none;
      color: inherit;
      @include display-flex;
      @include align-items(center);
    }
  }

  img {
    margin-right: $gutter_width/2;
  }
}

section.heroVideo {
  @extend %hero;


  iframe {
    position: absolute;
    @include center(xy);
    z-index: 100;
  }

}

.textColumns {
  @include display-flex;
  @include flex-direction(column);
  padding: $gutter_width*4 0;

  div.column {
    padding: $gutter_width;
    width: 100%;

    @include MQ(md) {
      width: percentage(1/4);
    }
  }

  p,
  a,
  b {
    // font-size: .9rem;
  }

  p {
    // line-height: 1.5;
  }

  ul {
    padding: 0px;
  }

  a {
    border-bottom: 2px solid rgba(250, 193, 20, 0.9);
    color: $brand-yellow;
  }

  b {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    // font-size: 1rem;
    display: block;
    margin-bottom: 5px;
  }

  @include MQ(md) {
    @include flex-direction(row);
  }
}


section.tabs {
  width: 100%;
  min-height: 100vh;
  overflow-y: visible;
  position: relative;

  .tabPages {
    @include display-flex;
    width: 100%;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
  }

  div.peopleCopy {
    width: 100%;
    padding: $gutter_width*3 $gutter_width;

    h2,
    a {
      color: $blue;
    }

    a {
      border-bottom: 2px solid $blue;
    }

    @include MQ(md) {
      width: percentage(1/2);
    }
  }

  .tabPage {
    @include display-flex;
    @include align-items(center);
    @include flex-direction(column);
    width: 100%;
    position: absolute;
    min-height: 100vh;
    padding-top: 30px;
    @include transition(left 300ms ease-in-out);
    @include transform(scale(0));

    &.active {
      @include transform(none);
    }
  }

  .tab-section {
    @include display-flex;
    @include align-items(center);
    @include flex-direction(column);
  }

}

nav.tabs {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  width: 100%;
  background-color: $white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  &.fixed {
    position: fixed;
    z-index: 100;
  }
}

nav.tabs div.wrap {
  width: 100%;
  @include display-flex;
  @include align-items(center);
  @include flex-wrap(wrap);


  @include MQ(md) {
    @include justify-content(space-between);
    @include flex-wrap(nowrap);

    a.tab {
      width: auto;
    }
  }
}

a.tab {
  @include display-flex;
  @include justify-content(center);
  @include transition(all 200ms ease);
  padding: $gutter_width/2;
  flex-grow: 1;
  color: #000;
  border-bottom: 3px solid transparent;
  width: 50%;
  font-family: "Visby";
  font-weight: 300;

  &.active,
  &:hover {
    border-bottom: 3px solid $blue;
    color: $blue;
  }

  &:hover {
    background-color: #f8f8f8;
  }

  @include MQ(md) {
    padding: $gutter_width;
    width: 100%;
  }
}

div.people {
  width: 100%;
  @include display-flex;
  @include flex-direction(column);

  hr {
    display: inline-block;
    width: 30%;
    border-top: 1px solid #FAC012;
    border-bottom: 0px;
    margin: 2px 0;
  }

  @include MQ(sm) {
    @include flex-direction(row);
    @include flex-wrap(wrap);
  }

  @include MQ(md) {
    width: percentage(3/4);
    @include align-self(center);

  }
}

.person {
  width: 100%;

  .personMeta img {
    max-width: 100%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  p {
    font-weight: bold;
  }

  p:last-of-type {
    opacity: .75;
    font-weight: normal;
  }

  img {
    width: 100%;
  }

  @include MQ(sm) {
    width: percentage(1/2);
  }

  @include MQ(md) {
    width: percentage(1/3);
  }

  @include MQ(l) {
    width: percentage(1/4);
  }
}


div.tiles {
  width: 100%;
  background-color: #fff;

  div.wrap {
    @include display-flex;
    @include flex-wrap(wrap);
    width: 100%;
    max-width: 1500px;
  }

  p.type {
    display: none;
    color: $white;
    background-color: $light-blue;
    text-transform: uppercase;
    font-family: "Visby";
    // font-size: .7rem;
    font-weight: bold;
    letter-spacing: 1px;
  }

  div.tile {
    @include display-flex;
    @include flex-direction(column);
    @include align-items(stretch);
    width: 100%;
    position: relative;

    &.gold p.type {
      background-color: #f7b412;
    }

    &.silver p.type {
      background-color: #005a80;
    }

    &.copper p.type {
      background-color: #e74c3c;
    }

    &.platinum p.type {
      background-color: #212f3f;
    }

    &.contributing p.type {
      background-color: #aeaeae;
    }


    @include MQ(sm) {
      width: percentage(1/2);
    }

    @include MQ(md) {
      width: percentage(1/4);
    }

    @include MQ(l) {
      width: percentage(1/5);
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 100%;
      max-height: 140px;
      opacity: 0;
      transition: opacity 200ms ease;

      &.loading {
        opacity: 0.1;
      }

      &.loaded {
        opacity: 1;
      }
    }
  }


  a.tile-link {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    display: block;
  }


  .imageContainer {
    position: relative;
    height: 200px;
  }

  .logo-default {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: left;

    font-family: "Visby";
    font-weight: 300;
    padding: 1em 0.75em;
    letter-spacing: 2px;
    color: $black;

    &.variation-1 {
      border-left: 2px solid $light-blue;
      padding: 0 0.75em;
    }

    &.variation-2 {
      border-bottom: 2px solid $light-blue;
      padding: 1em 0;
    }
  }

  .gold .logo-default {
    border-color: #f7b412;
  }

  .silver .logo-default {
    border-color: #005a80;
  }

  .copper .logo-default {
    border-color: #e74c3c;
  }

  .platinum .logo-default {
    border-color: #212f3f;
  }

  .contributing .logo-default {
    border-color: #aeaeae;
  }

  .tier,
  .location {
    position: absolute;
    bottom: 1.5em;
    right: 2em;
    text-transform: uppercase;
    font-size: 0.6rem;
    text-align: right;
  }
}

.mask,
.modal-mask {
  display: none;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #000;
  opacity: 0.5;
  cursor: pointer;
  z-index: 220;
}

.arrows {
  position: relative;
  height: 100%;
  max-width: 1300px;
  margin: 0 auto;

  &>span {
    position: absolute;
    height: 44px;
    width: 44px;
    top: 50%;
    transform: translateY(-50%);
    left: 3%;
    padding: 0.8em;
    z-index: 500;
    background: rgba($color: #fff, $alpha: 0.6);
    cursor: pointer;
    border-radius: 50%;
    text-align: center;

    &.next {
      right: 3%;
      left: initial;
    }
  }

  @include MQ(md) {
    bottom: inherit;
    // top: 3em;
    // right: 6em;
    left: inherit;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 600;
  display: none;
  overflow: auto;

  // @include MQ(sm) {
  //   overflow: hidden;
  // }

  .modal__box {
    width: 90%;
    max-width: 800px;
    // min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 600;
    background: #fff;
    padding: 1em 1em 3em 1em;
    transition: opacity 500ms ease;
    overflow: auto;

    @include MQ(md) {
      padding: 3em;
    }

    * {
      opacity: 0;
    }
  }

  &.active .modal__box * {
    opacity: 1;
  }

  .modal__header {
    padding-bottom: 1.5em;
    color: $blue;

    img {
      max-width: 45px;
    }
  }

  // .modal__account-name {
  //   padding: 2em 0;
  //   // border-bottom: 1px solid gray;
  // }

  .modal__account-desc {
    padding-top: 1em;
    color: #000;
  }

  .modal__company-details {
    padding: 2em 0;
  }

  .modal__content {
    // padding-top: 3rem;
    // width: 50%;
    // display: inline-block;
    vertical-align: top;
  }

  .modal__logo {
    width: 50%;
    display: inline-block;
    padding: 2rem;
    text-align: center;

    img {
      max-width: 100%;
      max-height: 200px;
    }
  }

  .closeModal {
    position: absolute;
    top: 1em;
    right: 1em;
    cursor: pointer;

    @include MQ(md) {
      top: 3em;
      right: 3em;
    }
  }
}

section.mobileTable {

  div.tableHeader {
    padding-bottom: 0px;
    padding-top: $gutter_width;

    h3 {
      color: $brand-yellow;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 1px;
      border-bottom: 1px solid #aaa;
      padding-bottom: $gutter_width/2;
    }
  }

  p {
    padding: $gutter_width/4 0px;
    font-weight: bold;
    // line-height: 1.25em;
    color: #616A6E;
  }

  div.price {

    h4,
    h5 {
      color: $brand-yellow;
    }

    a {
      // line-height: 1.5;
      display: inline-block;
      border-bottom: 2px solid $brand-yellow;
    }
  }

  @include MQ(md) {
    display: none;
  }
}


section.table {
  width: 100%;
  display: none;

  div.tableHeader,
  div.tableRows,
  div.row,
  div.tablePrices {
    @include display-flex;
    @include justify-content(space-between);
    text-align: center;
  }

  div.tableHeader,
  div.tablePrices {
    background-color: #efefef;
    padding: $gutter_width 0px;

    div {
      width: percentage(1/5);
    }

    p,
    a {
      text-transform: uppercase;
      color: #FAC012;
      font-weight: 600;
      font-family: "Visby";
      letter-spacing: 1px;
      // font-size: .9rem;
    }

    a {
      border-bottom: 2px solid $brand-yellow;
    }
  }

  div.tablePrices {
    background-color: $white;
  }

  div.tableRows {
    @include flex-direction(column);
  }

  div.row {
    border-bottom: 1px solid #979797;
    @include align-items(center);
  }

  div.cell {
    width: percentage(1/5);
    @include display-flex;
    @include align-items(center);
    @include justify-content(center);

  }

  div.featureTitle {
    width: percentage(1/5);
    text-align: center;

    p {
      padding: $gutter_width/2 $gutter_width;
      font-weight: 600;
      font-family: "Visby";
      color: #616A6E;
      // font-size: .9rem;
    }
  }

  @include MQ(md) {
    display: block;
  }
}


section.tracks {
  width: percentage(2/3);
  @include display-flex;
  @include flex-direction(column);
  margin: 0 auto;
  padding: $gutter_width*2 $gutter_width/2;

  ul {
    padding: 0px;
    margin: 0px;
    list-style: none;
  }

  li.track {
    padding: $gutter_width*2 $gutter_width/2;

    h4 {
      color: $brand-yellow;
    }
  }

  p a {
    color: $brand-yellow;
    text-decoration: underline;
  }
}

section.linkList {
  width: 100%;
  @include display-flex;
  @include flex-direction(column);
  @include align-items(center);
  padding: $gutter_width*3 0px;

  ul {
    width: 100%;
    text-align: center;
  }

  h2 {
    display: inline-block;
  }

  hr {
    border: 0;
    border-bottom: 2px solid #FAC012;
    display: inline-block;
    width: 30%;
  }

  li a {
    font-family: "Visby";
    font-weight: bold;
    color: #525453;
    opacity: .75;
    @include transition(opacity 200ms ease);

    &:hover {
      opacity: 1;
    }
  }

  p {
    padding-top: 2em;
  }

}

section.featureGrid {
  text-align: center;
  @include display-flex;
  @include flex-direction(column);
  width: 100%;
  background-color: #fafafa;
  padding: $gutter_width*5 0px;

  div.copy {

    h2 {
      color: $brand-yellow;
    }
  }


  ul {
    margin: 0px;
    padding: $gutter_width;
    width: 100%;
  }

  li {
    list-style: none;
    text-align: left;

    @include MQ(md) {
      padding: $gutter_width;
      width: percentage(1/2);
    }
  }

  @include MQ(md) {

    ul,
    div.copy {
      width: percentage(2/3);
      @include display-flex;
      @include align-self(center);
      @include flex-wrap(wrap);
    }

    div.copy {
      width: percentage(1/2);

      p {
        text-align: left;
      }
    }

    div.copy {
      @include flex-direction(column);
    }
  }
}


section.peoplePreview {
  padding: $gutter_width*5 0;
  @include display-flex;
  @include flex-direction(column);

  div.copy {
    text-align: center;

    h2 {
      color: $brand-yellow;
    }
  }

  div.people {
    @include display-flex;
    @include justify-content(center);
    @include align-self(center);

  }
}


section.logoReel {
  @include display-flex;
  @include flex-direction(column);

  h2 {
    color: $white;
    text-align: center;
    font-weight: bold;
  }

  div.copy,
  ul.logos {
    padding: $gutter_width*3 0px;
  }

  ul.logos {
    @include display-flex;
    @include align-items(center);
    @include justify-content(center);
    @include flex-direction(column);
    width: 100%;

    @include MQ(sm) {
      width: percentage(1/2);
      @include align-self(center);
      @include flex-direction(row);
    }
  }

  img {
    max-width: 100%;
  }

  li.logo {
    width: 100%;
    padding: $gutter_width;

    @include MQ(md) {
      width: percentage(1/2);
    }
  }
}

section.imagePanels {
  @include display-flex;
  @include flex-direction(column);
  background-color: #fafafa;
  padding: $gutter_width*5 0px;

  div.panels {
    @include display-flex;
    @include flex-direction(column);

    @include MQ(md) {
      @include flex-direction(row);
      @include align-self(center);
      width: percentage(5/6);

    }
  }

  h2 {
    text-align: center;
    margin-bottom: $gutter_width*2;
  }

  div.panel {
    position: relative;
  }

  div.panelContent {
    position: relative;
    height: 425px;
    @include display-flex;
    @include align-items(flex-end);
  }

  div.copy {
    z-index: 100;
    color: $white;
    position: relative;
    font-weight: bold;
    padding: $gutter_width*2;
  }

  div.background {
    width: 100%;
    height: 100%;
    position: absolute;
    @include center(xy);
    z-index: 50;
  }
}


section.colorPanels {
  @include display-flex;
  @include flex-direction(column);

  div.panel {
    width: 100%;
    @include display-flex;
    @include justify-content(center);
    padding: $gutter_width*5 0px;

    @include MQ(md) {
      width: percentage(1/2);
    }
  }

  h3 {
    text-align: center;
  }

  a.cappedLink {
    @include display-flex;
    @include align-items(center);
    padding-left: $gutter_width*2;

    img {
      margin-right: $gutter_width/2;
    }
  }

  div.copy {
    color: $white;
    width: 100%;

    @include MQ(sm) {
      width: percentage(2/3);
    }

    @include MQ(md) {}
  }

  ul {
    padding: 0px;
    margin: 0px;
    list-style: none;
  }

  li {
    padding: $gutter_width/2;
    padding-left: $gutter_width*2;

    &:before {
      content: "\f061";
      font-family: FontAwesome;
      display: inline-block;
      margin-left: -$gutter_width*1.2;
      width: $gutter_width*1.2;
    }
  }


  @include MQ(md) {
    @include flex-direction(row);
  }
}



section.productFeature {
  @include display-flex;
  @include align-items(center);
  @include flex-direction(column);
  padding: $gutter_width*5 $gutter_width;

  @include MQ(sm) {
    padding: $gutter_width*5 $gutter_width*3;
  }

  @include MQ(md) {
    @include flex-direction(row);
    @include justify-content(space-between);
  }

  @include MQ(l) {
    padding: $gutter_width*5 $gutter_width*7;
  }

  div.copy,
  img.product {
    width: 100%;

    @include MQ(md) {
      width: percentage(1/2);
    }
  }
}


section.heroSmall {
  height: 50vh;
  @include display-flex;
  @include align-items(center);
  @include justify-content(center);
  @include flex-direction(column);
  width: 100%;

  div.copy {
    text-align: center;

    @include MQ(md) {
      width: percentage(2/3);
    }
  }

  a {
    color: $brand-yellow;
    border-bottom: 2px solid rgba(250, 193, 20, 0.9);
    // font-size: 1rem;
  }
}

.personModal {

  .modal__content {
    @include MQ(sm) {
      @include display-flex;
    }
  }

  .modal__header>div {
    @include display-flex;
  }

  .personBio {
    width: 100%;
    overflow-y: scroll;
    max-height: 20vh;

    p,
    a,
    b {
      font-family: "Source Sans Pro";
      // font-size: 1rem;
      color: #000;
      // line-height: 1.5;
      letter-spacing: 0px;
    }

    p {
      margin-bottom: $gutter_width/2;

      &:first-of-type {
        margin: 0px;
      }
    }

    @include MQ(sm) {
      width: percentage(3/5);
      max-height: 40vh;
    }

    @include MQ(l) {
      height: 60vh;
      max-height: 300px;
    }
  }

  .personMeta {
    @include display-flex;
    @include flex-direction(column);
    width: 100%;

    img {
      margin-bottom: 1em;
    }

    @include MQ(sm) {
      width: percentage(2/5);
    }
  }
}

// featured story
.featured-story {
  min-height: 200px;
  margin-bottom: 5em;

  @include MQ(md) {
    display: grid;
    grid-template-columns: minmax(300px, 1fr) minmax(300px, 1fr);
  }
}

.featured-story__description {
  border: 3px solid $brand-gray;
  padding: 2em;

  // .featured-page & {
  //   border-style: double;
  // }

  @include MQ(md) {
    margin-top: 50px;
    padding: 2em 5em 2em 2em;
  }

  .desc-break {
    border: 1px solid $red;
    margin: 1em 0;
    width: 10em;

    .featured-page & {
      border-color: $aqua;
    }
  }

  p {
    margin-bottom: 2em;
  }

  a {
    border-bottom: 2px solid $red;

    .fa {
      font-size: 1rem;
    }

    .featured-page & {
      border-color: $aqua;
    }
  }

  .join-programs & {
    @include MQ(md) {
      padding: 4em 2em 4em 5em;
    }

    .desc-break {
      border-color: $blue;
    }

    a {
      border-color: $blue;
    }

  }

  .join-programs.jp-partner & {
    .desc-break {
      border-color: $aqua;
    }

    a {
      border-color: $aqua;
    }
  }
}

.featured-story__image {
  position: relative;
  align-self: center;

  img {
    width: 100%;
  }

  @include MQ(md) {
    margin-left: -60px;

    img {
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.16), 0 6px 12px rgba(0, 0, 0, 0.23);
    }
  }


  h3 {
    position: absolute;
    bottom: 2rem;
    left: 1rem;

    color: #fff;
    padding: 0.5em;
    z-index: 10;
    margin-bottom: 0;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 100%;
      // min-width: 100px;
      width: 100%;
      background: $red;
      opacity: 0.7;
      z-index: -1;

      .featured-page & {
        background: $aqua;
      }
    }
  }

  .join-programs & {
    @include MQ(md) {
      margin-right: -40px;
      margin-left: initial;
    }

    h3:before {
      background: $blue;
    }

  }

  .join-programs.jp-partner & {
    h3:before {
      background: $aqua;
    }
  }
}

div.thankYou {
  width: 100%;
  height: 80vh;
  @include display-flex;
  @include align-items(center);
  @include justify-content(center);
  @include flex-direction(column);
}

.notfound {
  min-height: 100vh;
  margin-top: 10em;

  @include MQ(sm) {
    margin-top: 0;
    display: grid;
    grid-template-columns: 50% 50%;
  }
}

.notfound__visual {
  position: relative;
  display: none;
  // background-image: url('/assets/images/shards.svg');
  height: 90vh;
  width: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @include MQ(sm) {
    display: block;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.notfound__logo img {
  width: 200px;
}

.notfound__content {
  position: relative;
}

.notfound__box {
  width: 100%;
  max-width: 450px;
  text-align: left;

  @include MQ(sm) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// .notfound__message {
//   text-align: left;
// }

.notfound__cta {
  text-align: left;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    a {
      color: $blue;
      // text-decoration: underline;
    }
  }
}

// slider component
.slider {

  .slider-images {
    .image-container {
      height: 85vh;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      outline: none;
    }

    .image-container img {
      width: 100%;
    }


    .slick-dots {
      bottom: 0;
    }
  }

  .slider {
    overflow-x: hidden;

    .slick-dotted.slick-slider {
      margin-bottom: 0;
    }
  }

  .slide-content__container {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 600px));
    justify-content: center;
    align-content: center;
    height: 100%;
    width: 100vw;
    z-index: 100;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: 90;
    }
  }

  .slide-content__box {
    // padding: 1em 3em;
    display: grid;
    // place-items: center;
    align-content: center;

    color: #fff;
    z-index: 100;
  }

  .slick-next {
    right: 16px !important;
  }

  .slick-prev {
    left: 16px !important;
    z-index: 1000 !important;
  }
}

// card component
// this can be used inside a slider or directly in a page
// currently used for home news and partner join case studies
.card {
  position: relative;
  font-size: 0;

  @include MQ(md) {
    min-height: 350px !important;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
  }

  img {
    width: 100%;
  }

  .card__content {
    position: relative;
    // min-height: 250px;
    width: 100%;
    padding: 2em;
    font-size: initial;
    z-index: 100;

    @include MQ(md) {
      position: absolute;
      bottom: -5em;
      left: -2em;
      width: 46%;
      min-height: 100px;
      padding: 1em 2em;
    }

    &>* {
      position: relative;
    }

    .card__meta {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-family: "Visby";
      font-weight: 400;
      color: #9B9B9B;
    }

    .card__meta,
    .card__title {
      color: #fff;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: $light-blue;
      transition: opacity 300ms ease;

      // @include MQ(md) {
      //   opacity: 0.7;
      // }
    }

    &:hover {
      cursor: pointer;

      &:before {
        opacity: 0.9;
      }
    }
  }
}

// small cards meant for horizontal alignment
.small_card__container {
  display: grid;
  grid-gap: 2rem;

  &.scc_two {
    @include MQ(md) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &.scc_three {
    @include MQ(md) {
      grid-template-columns: 1fr 1fr 1fr;

    }

  }

  &.scc_four {
    @include MQ(md) {
      grid-template-columns: 1fr 1fr 1fr 1fr;

    }

  }
}

.small_card {
  position: relative;
  background-color: #fff;
  color: #000;
  padding: 2rem 2rem 5rem;
  margin-bottom: 2rem;

  &.program_item {
    padding: 2rem;
  }
}

.small_card__cta {
  position: absolute;
  bottom: 2rem;
  left: 2rem;
}

// borrowing styles from the card component above but in a smaller grid size
.mini-card {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 350px;
  margin-bottom: 2rem;

  .card__content {
    bottom: 0;
    left: 0;
    // min-height: 250px;
    width: 100%;
    padding: 2em;

    @include MQ(md) {
      position: absolute;
      // min-height: 100px;
    }

    &>* {
      position: relative;
    }

    .card__meta {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-family: "Visby";
      font-weight: 400;
      color: #9B9B9B;
    }

    .card__meta,
    .card__title {
      color: #fff;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: $blue;
      opacity: 0.7;
      transition: opacity 300ms ease;
    }

    &:hover {
      cursor: pointer;

      &:before {
        opacity: 0.8;
      }
    }
  }
}

// jumbotron
.jumbotron {
  text-align: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 1rem;
  margin-bottom: 3rem;

  .module__description {
    margin-bottom: 1rem;
  }
}

.share-bar {
  width: 250px;
  // margin: 3em auto;
  // text-align: center;
}

.share-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  a {
    width: 100%;
  }
}

.custom-slider-left,
.custom-slider-right {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  cursor: pointer;
  background: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.custom-slider-left:hover,
.custom-slider-left:focus,
.custom-slider-right:hover,
.custom-slider-right:focus {
  outline: none;
}

.custom-slider-left:hover,
.custom-slider-left:focus,
.custom-slider-right:hover,
.custom-slider-right:focus {
  opacity: 1;
}

.custom-slider-left.slick-disabled,
.custom-slider-right.slick-disabled {
  opacity: 0.25;
}

.custom-slider-left {
  left: -30px;
}

.custom-slider-right {
  right: -30px;
}


.subnav {
  list-style: none;
  padding: 0;
  text-align: center;

  li {
    vertical-align: middle;

    @include MQ(md) {
      display: inline-block;

      &::after {
        content: '|';
        color: $brand-gray;
      }

      &:last-of-type::after {
        content: '';
      }
    }
  }

  a {
    text-decoration: underline;
    opacity: 0.7;
    transition: opacity 300ms ease;

    &:hover {
      opacity: 1;
      text-decoration: none;
    }
  }
}


.banner {
  .banner-container {
    max-width: 800px;
    margin: 0 auto;
  }

  img {
    position: absolute;
    height: 100px;
    opacity: 0.2;
  }
}

.box-icon__container {
  position: absolute;
  top: -6rem;
  left: 50%;
  transform: translateX(-50%);
  // background-color: $aqua;
  border-radius: 100%;
  width: calc(100px + 4rem);
  height: calc(100px + 4rem);
  padding: 2rem;

  display: flex;
  align-items: center;


  img {
    max-width: 100px;
  }
}

.bg_img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;

  @include MQ(md) {
    background-size: contain;
  }
}


.vertical-card {
  position: relative;
  display: grid;
  z-index: 20;
  background-color: #f0f0f0;
  transition: margin-top 300ms ease;
  min-height: 74%;

  a & {
    &:hover {
      margin-top: -5px;
    }
  }

  &.vc-min-height {
    @include MQ(md) {
      min-height: 12rem;
    }
  }


  &:before {
    content: "";
    position: absolute;
    width: 95%;
    height: 100%;
    right: -0.5rem;
    top: 0.5rem;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='3' height='3'%3E%3Ccircle cx='2.5' cy='2.5' r='0.9' fill='%23FECF5D'/%3E%3C/svg%3E") repeat bottom right;
    z-index: 0;
  }

  &.vci-blue {
    &:before {
      background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='3' height='3'%3E%3Ccircle cx='2.5' cy='2.5' r='0.9' fill='%231C5371'/%3E%3C/svg%3E") repeat bottom right;
    }
  }

  &.vci-aqua {
    &:before {
      background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='3' height='3'%3E%3Ccircle cx='2.5' cy='2.5' r='0.9' fill='%23307A83'/%3E%3C/svg%3E") repeat bottom right;
    }

  }

  .global-section & {
    opacity: 0.9;
    box-shadow: 0 2px 35px -10px rgba(0, 0, 0, .5);

    &:before {
      background: none;
    }
  }
}

.vertical-card-inner {
  text-align: center;
  z-index: 20;
  position: relative;
  background: #f0f0f0;
  padding: 2rem 0.6rem;

  &.pt-5 {
    min-height: 255px;
  }
}

// .vc-description {
//   position: absolute;
//   left: 50%;
//   transform: translateX(-50%);
//   width: calc(100% - 4rem);
// }

.global-charts {
  img {
    width: 100%;

    // @include MQ(md) {
    //   width: 49.5%;
    //   display: inline-block;

    // }
  }
}

.thanks {
  // padding: 6em 0 !important;
  background-color: #f0f0f0;

  .yir22 & {
    background-color: #fff;
  }

  .headline {
    color: #fff;
  }

  img {
    position: relative;
    z-index: 100;
  }

  .button {
    position: relative;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: -15%;
    left: 0;
    width: 100%;
    height: 107%;
    background: linear-gradient(-5deg, $black 25%, darken($black, 7%) 100%);
    transform: skewY(-2deg);
    opacity: 1;
    z-index: 0;
  }
}


.video-section {
  .video-container {
    max-width: 800px;
    margin: 0 auto;
  }

  &.video-small {
    .video-container {
      max-width: 500px;
    }
  }

  &.video-large {
    .video-container {
      max-width: none;
    }
  }
}

.drop-shadow-img {
  filter: drop-shadow(5px 5px 13px gray);
}

.logo-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 24px;
  justify-items: center;
  align-items: center;
}

.logo-item {
  text-align: center;
}

.logo-image {
  max-width: 100%;
  height: auto;
}

.logo-label {
  margin-top: 10px;
  font-size: 1rem;
}

.card-body {
  img {
    width: 100%;
  }
}

.tag_slider {
  .event-date {
    width: 150px;
    transition: width 200ms ease;
  }

  .he-slide:hover {
    .event-date {
      width: 170px;
    }
  }
}