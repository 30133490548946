$brand-yellow:            #FECF5D;
$brand-gray:              #606a6e;
$light-gray:              #C9CAC8;
$lightest-gray:           #f0f0f0;
$light-blue:              #00ADD0;
$blue:                    #1C5371;
$black:                   #2c2e37;
$white:                   #ffffff;
$green:                   #4cb944;
$red:                     #DB1F5A;
$aqua:                    #307A83;

// previous colors
// $brand-yellow:            fac114;
// $brand-gray:              606a6e;
// $light-gray:              d7d7d7;
// $light-blue:              00afec;
// $blue:                    005c96;
// $black:                   2c2e37;
// $white:                   ffffff;
// $green:                   4cb944;
// $red:                     d72638;

:root {
  --brand-yellow: #FECF5D;
  --brand-gray: #606a6e;
  --light-gray: #C9CAC8;
  --lightest-gray: #f0f0f0;
  --light-blue: #00ADD0;
  --blue: #1C5371;
  --black: #2c2e37;
  --white: #ffffff;
  --green: #4cb944;
  --red: #DB1F5A;
  --aqua: #307A83;
}