

// Layout
// ••••••••••••••••••••••••••••••••••••••••••••••••••

@mixin bg-size($size: 100% auto) {
    -webkit-background-size: $size;
    -moz-background-size: $size;
    background-size: $size;
}

@mixin center($xy:xy) {
  @if $xy == xy {
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    @include transform(translateX(-50%) translateY(-50%));
  }
  @else if $xy == x {
    left: 50%;
    right: auto;
    @include transform(translateX(-50%));
  }
  @else if $xy == y {
    top: 50%;
    bottom: auto;
    @include transform(translateY(-50%));
  }
}


// Effects
// ••••••••••••••••••••••••••••••••••••••••••••••••••

@mixin animate($args...) {
  -webkit-animation: $args;
     -moz-animation: $args;
      -ms-animation: $args;
       -o-animation: $args;
          animation: $args;
}

@mixin backface-visibility {
  -webkit-backface-visibility: hidden;
     -moz-backface-visibility: hidden;
      -ms-backface-visibility: hidden;
          backface-visibility: hidden;
}

@mixin border-box {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

@mixin border-radius($amount) {
  -webkit-border-radius: $amount;
     -moz-border-radius: $amount;
       -o-border-radius: $amount;
          border-radius: $amount;
}

@mixin box-shadow($attrs) {
  -webkit-box-shadow: $attrs;
     -moz-box-shadow: $attrs;
       -o-box-shadow: $attrs;
          box-shadow: $attrs;
}

@mixin opacity($amount) {
  -webkit-opacity: $amount;
     -moz-opacity: $amount;
       -o-opacity: $amount;
          opacity: $amount;
}

@mixin filter($attrs) {
  -webkit-filter: $attrs;
     -moz-filter: $attrs;
       -o-filter: $attrs;
          filter: $attrs;
}

@mixin transform($attrs) {
  -webkit-transform: $attrs;
     -moz-transform: $attrs;
      -ms-transform: $attrs;
          transform: $attrs;
}

@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}

@mixin scale($scale) {
   @include transform(scale($scale));
}

@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}

@mixin skew ($x, $y) {
   @include transform(skew(#{$x}deg, #{$y}deg));
}

@mixin transition($attrs) {
  -webkit-transition: $attrs;
     -moz-transition: $attrs;
      -ms-transition: $attrs;
          transition: $attrs;
}

@mixin transition-delay($delay) {
  -webkit-transition-delay: $delay;
     -moz-transition-delay: $delay;
      -ms-transition-delay: $delay;
          transition-delay: $delay;
}

@mixin transform-origin($args) {
  -webkit-transform-origin: $args;
     -moz-transform-origin: $args;
      -ms-transform-origin: $args;
       -o-transform-origin: $args;
          transform-origin: $args;
}

@mixin animateCss($duration: 3s, $delay: 2s, $iteration: 1) {
  -webkit-animation-duration: $duration;
  -webkit-animation-delay: $delay;
  -webkit-animation-iteration-count: $iteration;
  -moz-animation-duration: $duration;
  -moz-animation-delay: $delay;
  -moz-animation-iteration-count: $iteration;
  -ms-animation-duration: $duration;
  -ms-animation-delay: $delay;
  -ms-animation-iteration-count: $iteration;
  -o-animation-duration: $duration;
  -o-animation-delay: $delay;
  -o-animation-iteration-count: $iteration;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-iteration-count: $iteration;
}

@mixin mobile-scroll {
  -webkit-overflow-scrolling: touch;
                  overflow-y: scroll;
}

// Development
// ••••••••••••••••••••••••••••••••••••••••••••••••••

@mixin debug { background: #FFFFEE; outline: 1px solid red !important; }
@mixin hide { display: none; }
@mixin unsized { img:not([width]):not([height]) { outline: 2px solid red !important; } }

// Hacks
// ••••••••••••••••••••••••••••••••••••••••••••••••••

@mixin clearfix {
  &:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
  zoom: 1;
}


// Responsive ———————————————————————————————— •
// ——————————————————————————————————————————— •

$xs:    340px;
$sm:     480px;
$md:     769px;
$ml:    910px;
$l:     1180px;
$xl:     1480px;

@mixin MQ($canvas) {
  @if $canvas == xs {
   @media only screen and (min-width: $xs) { @content; }
  }
  @else if $canvas == sm {
   @media only screen and (min-width: $sm) { @content; }
  }
  @else if $canvas == md {
   @media only screen and (min-width: $md) { @content; }
  }
  @else if $canvas == ml {
   @media only screen and (min-width: $ml) { @content; }
  }
  @else if $canvas == l {
   @media only screen and (min-width: $l) { @content; }
  }
  @else if $canvas == xl {
   @media only screen and (min-width: $xl) { @content; }
  }
}


// Retina
// ••••••••••••••••••••••••••••••••••••••••••••••••••

@mixin retinafy($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    background-image: url($image);
    background-size: $width $height;
  }
}


// Flexbox Mixins
// --------------------------------------------------
// http://philipwalton.github.io/solved-by-flexbox/
// https://github.com/philipwalton/solved-by-flexbox

@mixin display-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

.display-flex {
  @include MQ(md) {
    @include display-flex;
  }
}

@mixin display-inline-flex {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

@mixin flex-direction($value: row) {
  @if $value == row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
  } @else if $value == column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
  } @else if $value == column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
  } @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
  }
  -webkit-flex-direction: $value;
  -moz-flex-direction: $value;
  -ms-flex-direction: $value;
  flex-direction: $value;
}

@mixin flex-wrap($value: nowrap) {
  // No Webkit Box fallback.
  -webkit-flex-wrap: $value;
  -moz-flex-wrap: $value;
  @if $value == nowrap {
      -ms-flex-wrap: none;
  } @else {
      -ms-flex-wrap: $value;
  }
  flex-wrap: $value;
}

@mixin flex($fg: 1, $fs: null, $fb: null) {
  -webkit-box-flex: $fg;
  @if $fs == null and $fb == null {
    -webkit-flex: $fg;
    -moz-flex: $fg;
    -ms-flex: $fg;
    flex: $fg;
  } @else {
    -webkit-flex: $fg $fs $fb;
    -moz-flex: $fg $fs $fb;
    -ms-flex: $fg $fs $fb;
    flex: $fg $fs $fb;
  }
}

@mixin flex-flow($values: (row nowrap)) {
  // No Webkit Box fallback.
  -webkit-flex-flow: $values;
  -moz-flex-flow: $values;
  -ms-flex-flow: $values;
  flex-flow: $values;
}

@mixin align-items($value: stretch) {
  @if $value == flex-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
    -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
  -moz-align-items: $value;
  align-items: $value;
}

@mixin align-self($value: auto) {
  -webkit-align-self: $value;
  -moz-align-self: $value;
  @if $value == flex-start {
    -ms-flex-item-align: start;
  } @else if $value == flex-end {
    -ms-flex-item-align: end;
  } @else {
    -ms-flex-item-align: $value;
  }
  align-self: $value;
}

@mixin align-content($value: stretch) {
  -webkit-align-content: $value;
  -moz-align-content: $value;
  @if $value == flex-start {
    -ms-flex-line-pack: start;
  } @else if $value == flex-end {
    -ms-flex-line-pack: end;
  } @else {
    -ms-flex-line-pack: $value;
  }
  align-content: $value;
}

@mixin justify-content($value: stretch) {
  @if $value == flex-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
  } @else if $value == center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
  } @else {
    -webkit-box-pack: $value;
    -ms-flex-pack: $value;
  }
  -webkit-justify-content: $value;
  -moz-justify-content: $value;
  justify-content: $value;
}

.flex-center {
  @include justify-content(center)
}

@mixin column-size($size) {
  @include flex(0, 0, $size);
  width: $size !important;
}


// Gradients
// ••••••••••••••••••••••••••••••••••••••••••••••••••
@mixin fadeToBlack {
 background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 48%, rgba(0,0,0,0.91) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(48%,rgba(0,0,0,0.7)), color-stop(100%,rgba(0,0,0,0.91))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.7) 48%,rgba(0,0,0,0.91) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.7) 48%,rgba(0,0,0,0.91) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.7) 48%,rgba(0,0,0,0.91) 100%); /* IE10+ */
  background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.7) 48%,rgba(0,0,0,0.91) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#e8000000',GradientType=0 ); /* IE6-9 */
}

// Shapes
// ••••••••••••••••••••••••••••••••••••••••••••••••••

@mixin arrow-up($color:black, $thickness:6px) {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    border-color: transparent transparent $color transparent;
    border-style: solid;
    border-width: $thickness;
}

@mixin arrow-down($color:black, $thickness:6px) {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    border-color: $color transparent transparent transparent;
    border-style: solid;
    border-width: $thickness;
}


div.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);

  // .hero-angled & {
  //   -webkit-clip-path: polygon(100% 0, 100% 90%, 0% 100%, 0 0%);
  //   clip-path: polygon(100% 0, 100% 90%, 0% 100%, 0 0%);
  // }
}

div.darkOverlay {
  @include transform(scale(0));
  @include transition(opacity 400ms ease-in-out);
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(0,0,0,.85);
  width: 100%;
  height: 100vh;
  opacity: 0;

  &.active {
    @include transform(scale(1));
    opacity: .35;
  }
}


%hero {
  width: 100%;
  height: 85vh;
  position: relative;
  @include bg-size(cover);

  div.copy {
    position: absolute;
    width: 100%;

    @include MQ(md) {
      width: percentage(1/2);
    }
  }

  div.overlay {
    position: absolute;
    @include center(xy);
    width: 100%;
    height: 100%;
    opacity: .45;
  }
}


div.playButton {
  position: absolute;
  @include center(xy);
  @include display-flex;
  @include align-items(center);
  @include justify-content(center);

  div.circle {
    width: 60px;
    height: 60px;
    background-color: #fff;
    border-radius: 50%;
    @include display-flex;
    @include align-items(center);
    @include justify-content(center);
  }

  i.fa {
    opacity: .7;
    font-size: 1.6rem;
    color: $brand-yellow;
  }

  div.ring {
    border: 1px solid #fff;
    width: 80px;
    height: 80px;
    @include display-flex;
    position: absolute;
    @include center(xy);
    border-radius: 50%;
    opacity: .4;
    @include transition(all 300ms ease);

    &.inner {
      width: 70px;
      height: 70px;
      opacity: .6;
    }

    &.outer {
      width: 90px;
      height: 90px;
      opacity: .2;
    }
  }

  a:hover div.ring {
    width: 85px;
    height: 85px;

    &.inner {
      width: 75px;
      height: 75px;
    }

    &.outer {
      width: 95px;
      height: 95px;
    }
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

// $xs:    ;
// $S:     ;
// $M:     ;
// $ML:    ;
// $L:     ;
// $XL:     ;

$breakpoints: (xs, sm, md, ml, l, xl);
$spaceamounts: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 21); 
$sides: (top, bottom, left, right);

@each $size in $breakpoints {
  @include MQ($size) {
    @each $space in $spaceamounts {
      @each $side in $sides {
        .m#{str-slice($side, 0, 1)}-#{$size}-#{$space} {
          margin-#{$side}: #{$space}em !important;
        }
      
        .p#{str-slice($side, 0, 1)}-#{$size}-#{$space} {
          padding-#{$side}: #{$space}em !important;
        }
      }
    }

    .float-right-#{$size} {
      float: right;
    }

    .float-left-#{$size} {
      float: left;
    }
  }
}

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}em !important;
    }
  
    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}em !important;
    }
  }

  .m-#{$space} {
    margin: #{$space}rem !important;
  }

  .p-#{$space} {
    padding: #{$space}rem !important;
  }
}

// Burger parts
// Vendor prefixes
$sass-burger-add-vendor-prefixes: true !default;

// Burger
$height: 5px;
$gutter: 3px;

@mixin burger($width: 30px, $height: 5px, $gutter: 3px, $c: $accent, $border-radius: 0, $transition-duration: .3s) {
  $burger-height: $height;
  $burger-gutter: $gutter;

  position: relative;
  margin-top: $height + $gutter;
  margin-bottom: $height + $gutter;

  @if $sass-burger-add-vendor-prefixes {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  user-select: none;

  // 1. Fixes jagged edges in Firefox, see issue #10.
  &,
  &::before,
  &::after {
    display: block;
    width: $width;
    height: 1px;
    background-color: $c;
    outline: 1px solid transparent; // 1

    @if $border-radius !=0 {
      border-radius: $border-radius;
    }

    @if $sass-burger-add-vendor-prefixes {
      -webkit-transition-property: background-color, -webkit-transform;
      -moz-transition-property: background-color, -moz-transform;
      -o-transition-property: background-color, -o-transform;
    }

    transition-property: background-color,
    transform;

    @if $sass-burger-add-vendor-prefixes {
      -webkit-transition-duration: $transition-duration;
      -moz-transition-duration: $transition-duration;
      -o-transition-duration: $transition-duration;
    }

    transition-duration: $transition-duration;
  }

  &::before,
  &::after {
    position: absolute;
    content: "";
  }

  &::before {
    top: -($height + $gutter);
  }

  &::after {
    top: $height + $gutter;
  }
}


// Select parts of the burger
@mixin burger-parts {

  &,
  &::before,
  &::after {
    @content;
  }
}

@mixin burger-top {
  &::before {
    @content;
  }
}

@mixin burger-middle {
  & {
    @content;
  }
}

@mixin burger-bottom {
  &::after {
    @content;
  }
}


// Burger animations
@mixin burger-to-cross($color: auto) {
  $burger-height: $height;
  $burger-gutter: $gutter;

  & {
    background-color: transparent;
  }

  @if ($color !=auto) {

    &::before,
    &::after {
      background-color: $color;
    }
  }

  &::before {
    @if $sass-burger-add-vendor-prefixes {
      -webkit-transform: translateY($burger-gutter + $burger-height) rotate(45deg);
      -moz-transform: translateY($burger-gutter + $burger-height) rotate(45deg);
      -ms-transform: translateY($burger-gutter + $burger-height) rotate(45deg);
      -o-transform: translateY($burger-gutter + $burger-height) rotate(45deg);
    }

    transform: translateY($burger-gutter + $burger-height) rotate(45deg);
  }

  &::after {
    @if $sass-burger-add-vendor-prefixes {
      -webkit-transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
      -moz-transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
      -ms-transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
      -o-transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
    }

    transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
  }
}

// .mt-1 {
//   margin-top: 1rem !important;
// }

// .pt-1 {
//   padding-top: 1rem !important;
// }

// .mb-1 {
//   margin-bottom: 1rem !important;
// }

// .pb-1 {
//   padding-bottom: 1rem !important;
// }

// .ml-1 {
//   margin-left: 1rem !important;
// }

// .pl-1 {
//   padding-left: 1rem !important;
// }

// .mr-1 {
//   margin-right: 1rem !important;
// }

// .pr-1 {
//   padding-right: 1rem !important;
// }

// .mt-2 {
//   margin-top: 2rem !important;
// }

// .pt-2 {
//   padding-top: 2rem !important;
// }

// .mb-2 {
//   margin-bottom: 2rem !important;
// }

// .pb-2 {
//   padding-bottom: 2rem !important;
// }

// .ml-2 {
//   margin-left: 2rem !important;
// }

// .pl-2 {
//   padding-left: 2rem !important;
// }

// .mr-2 {
//   margin-right: 2rem !important;
// }

// .pr-2 {
//   padding-right: 2rem !important;
// }

// .mt-3 {
//   margin-top: 3rem !important;
// }

// .pt-3 {
//   padding-top: 3rem !important;
// }

// .mb-3 {
//   margin-bottom: 3rem !important;
// }

// .pb-3 {
//   padding-bottom: 3rem !important;
// }

// .ml-3 {
//   margin-left: 3rem !important;
// }

// .pl-3 {
//   padding-left: 3rem !important;
// }

// .mr-3 {
//   margin-right: 3rem !important;
// }

// .pr-3 {
//   padding-right: 3rem !important;
// }

// .mt-5 {
//   margin-top: 5rem !important;
// }

// .pt-5 {
//   padding-top: 5rem !important;
// }

// .mb-5 {
//   margin-bottom: 5rem !important;
// }

// .pb-5 {
//   padding-bottom: 5rem !important;
// }

// .ml-5 {
//   margin-left: 5rem !important;
// }

// .pl-5 {
//   padding-left: 5rem !important;
// }

// .mr-5 {
//   margin-right: 5rem !important;
// }

// .pr-5 {
//   padding-right: 5rem !important;
// }

// .mt-8 {
//   margin-top: 8rem !important;
// }

// .pt-8 {
//   padding-top: 8rem !important;
// }

// .mb-8 {
//   margin-bottom: 8rem !important;
// }

// .pb-8 {
//   padding-bottom: 8rem !important;
// }

// .ml-8 {
//   margin-left: 8rem !important;
// }

// .pl-8 {
//   padding-left: 8rem !important;
// }

// .mr-8 {
//   margin-right: 8rem !important;
// }

// .pr-8 {
//   padding-right: 8rem !important;
// }

// .mt-13 {
//   margin-top: 13rem !important;
// }

// .pt-13 {
//   padding-top: 13rem !important;
// }

// .mb-13 {
//   margin-bottom: 13rem !important;
// }

// .pb-13 {
//   padding-bottom: 13rem !important;
// }

// .ml-13 {
//   margin-left: 13rem !important;
// }

// .pl-13 {
//   padding-left: 13rem !important;
// }

// .mr-13 {
//   margin-right: 13rem !important;
// }

// .pr-13 {
//   padding-right: 13rem !important;
// }

// .mt-21 {
//   margin-top: 21rem !important;
// }

// .pt-21 {
//   padding-top: 21rem !important;
// }

// .mb-21 {
//   margin-bottom: 21rem !important;
// }

// .pb-21 {
//   padding-bottom: 21rem !important;
// }

// .ml-21 {
//   margin-left: 21rem !important;
// }

// .pl-21 {
//   padding-left: 21rem !important;
// }

// .mr-21 {
//   margin-right: 21rem !important;
// }

// .pr-21 {
//   padding-right: 21rem !important;
// }