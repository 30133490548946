.headline-cta-container {
  text-align: center;

  @include MQ(md) {
    text-align: left;
  }
}

// features
.features {
  background-color: #f0f0f0;
  padding-top: 4rem;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -2rem;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f0f0f0;
    color: #fff;
    transform: skewY(2deg);
    opacity: 1;
    z-index: 0;
  }

  // partner version
  &.partners {
    &::before {
      background: $aqua;
      transform: skewY(-2deg);
    }
  }
}

.features-container {
  position: relative;
  max-width: 1000px;
  display: block;
}

.featureB {
  text-align: center;
  color: #fff;
  margin-bottom: 3rem;
}

.featureC {
  background-color: lighten(#f0f0f0, 2%);
  margin-bottom: 5rem;
  padding: 1rem;
  min-height: 12rem;
  box-shadow: 0 2px 35px -10px rgba(0,0,0,.5);
  
  position: relative;
  z-index: 10;
}

.features-row:last-of-type {
  .featureC, .feature-mobile {
    margin-bottom: 0;
  }
}

.feature-mobile {
  position: relative;
  margin-bottom: 2rem;
}

.feature__icon {
  // position: relative;
  // height: 70%;
  margin-top: 0.5rem;

  
}

.fi-container {
  position: relative;

  &:before {
    content:"";
    position:absolute;
    width: 90%;
    height: 100%;
    // left: 0;
    right: -5.5rem;
    // bottom: 0;
    top: 1rem;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='3' height='3'%3E%3Ccircle cx='2.5' cy='2.5' r='0.9' fill='%232c2e37'/%3E%3C/svg%3E") repeat bottom right;
    z-index: 0;
    
    .even & {
      right: initial;
      left: -3.5rem;
    }
  }

}

.feature__icon-inner {
  position: relative;
  min-height: 150px;
  text-align: center;
  // background-color: $blue;
  padding: 1.8rem;
  display: grid;
  align-items: center;
  justify-content: center;
  // margin-left: -2rem;
  // margin-right: 2rem;
  right: -5.5rem;
  width: 80%;
  z-index: 100;
  

  img {
    max-height: 100px;
    max-width: 150px;
    vertical-align: middle;
  }

  .even & {
    right: initial;
    left: -3rem;
  }

  &.fi-inner__partner {
    // background-color: $aqua;
  }
}

.feature__icon-mobile {
  position: absolute;
  top: 0;
  left: 0;
  background: $blue;
  padding: 0.5rem;
  height: 46px;
  width: 46px;
  display: flex;
  align-items: center;

  img {
    width: 100%;
  }  

  &.fi-mobile__partner {
    background-color: $aqua;
  }
}

.feature__name {
  margin-bottom: 1rem;
}

.feature__description {
  text-align: left;
}

.details {
  background-color: #f0f0f0;
  padding-bottom: 17rem;
  
  &.partner-details {
    padding-bottom: 7rem;
  }
}

.member-story {
  position: relative;
  background-color: $black;

  .case-studies {
    max-width: 800px;
    width: 80%;
    margin: 0 auto;
    // margin-bottom: 6rem !important;

    .card__content:before {
      background-color: $blue;
    }
  }

  .case-study {
    position: relative;
    width: 100%;
    overflow: hidden;
    border: 3px solid $blue;
    
    @include MQ(md) {
      margin: 0 3rem;
      min-height: 350px;
      padding: 2rem;
    }
  }

  .case-study__label {
    padding: 1rem 2rem 2rem;
    width: 100%;
    background-color: $blue;
    color: #fff;

    @include MQ(md) {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    h2 {
      margin-bottom: 1rem;
    }
  }

  .case-study__quote {
    display: none;

    @include MQ(md) {
      display: block;
    }
    
    h4 {
      text-transform: none;
      margin-bottom: 1rem;
    }
  }
}

.join-case-studies {
  color: #fff;
}

.testimonials {
  width: 94%;
  margin: 0 auto;

  @include MQ(md) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 6rem 3rem;
    margin: 3rem auto;
    margin-bottom: -4rem;
  }
}

// .testimonial {
//   padding: 2rem;
//   background: #f0f0f0;
//   border: 3px solid $blue;
//   box-shadow: 0 6px 12px rgba(0, 0, 0, 0.16), 0 6px 12px rgba(0, 0, 0, 0.23);
//   margin: 2rem 0;
//   max-width: 600px;

//   h4 {
//     text-transform: none;
//     overflow: auto;
//   }

//   @include MQ(md) {
//     margin: 0;

//     &:first-of-type {
//       grid-column: 1/3;
//       justify-self: end;
  
//       transform: translate(25px, 50px);
//       padding: 2rem 5rem 5rem 2rem;
//     }
    
//     &:nth-of-type(2) {
//       grid-column: 3/5;
//       justify-self: start;
  
//       transform: translate(-50px, 0px);
//       padding: 2rem 2rem 5rem 2rem;
//     }
    
//     &:last-of-type {
//       grid-column: 2/4;
//       justify-self: center;
  
//       transform: translate(100px, -150px);
//     }

//   }
// }

.test__logo {
  text-align: center;
  margin-bottom: 2rem;

  img {
    max-width: 200px;
    // border-radius: 50%;
  }
}

.test__quote {
  margin-bottom: 1rem;
}

.join-member-stories {
  padding-bottom: 8rem;
  margin-top: -17rem;

  .vertical-card-inner {
    background-color: #fff;
  }
}

.split-sided-3-4 {

  @include MQ(l) {
    display: grid;
    grid-template-columns: 3fr 4fr;
    grid-gap: 4rem;
  }
}

.split-sided-even {
  padding: 5rem 0;
  @include MQ(l) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }

  img {
    width: 100%;
  }
}

.side {
  display: grid;
  align-items: center;
}

// data
.data__container {
  position: relative;

  @include MQ(sm) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
    // grid-gap: 2rem;
    margin-top: 2rem;
  }

  @include MQ(md) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 2rem 1rem;
  }
}

.data__point {
  text-align: center;
  z-index: 100;
}

.data__stat {
  color: $blue;

  #partners &, #partners-page & {
    color: $aqua;
  }
}

.data__description {
  width: 60%;
  margin: 0 auto;
  // font-size: 0.8rem;
}

.quotes {
  width: 96%;
  max-width: 700px;
  margin: 2rem auto 0;
  border: 3px solid #f0f0f0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.16), 0 6px 12px rgba(0, 0, 0, 0.23);
}

.quote__container {
  padding: 4rem 2rem;
  display: grid;
  grid-template-areas: 
          "img img img"
          "copy copy copy"
          "byline byline byline";
    grid-template-columns: 1fr 1fr 1fr;

  @include MQ(sm) {
    grid-template-areas: 
          "img copy"
          "....... byline";
    grid-template-columns: 120px auto;
    grid-gap: 0 2rem;
  }
  
  
  .quote__img {
    grid-area: img;
    position: relative;
    padding: 1rem;
    max-width: 150px;
    justify-self: center;

    &:after {
      content: '';
      position: absolute;
      top: 13px;
      right: 10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 65px 82px 0;
      border-color: transparent $brand-yellow transparent transparent;
      opacity: 1;
      z-index: 0;
      transform: rotate(-5deg);
    }

    img {
      position: relative;
      border-radius: 50%;
      width: 100%;
      z-index: 100;
    }
  }

  .quote__copy {
    grid-area: copy;

    .desc-break {
      width: 100px;
      border: 1px solid $brand-yellow;
      display: inline-block;
    }
  }

  .quote__byline {
    grid-area: byline;
  }
}

.global {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3rem;
  max-width: 1500px;
  margin: 8rem auto 0;

  &.investor {
    margin: 0 auto;
  }

  @include MQ(md) {
    grid-template-columns: 1fr 1fr;
  }

  .headline {
    display: grid;
    align-items: center;
    text-align: left;
    padding: 0 2rem;
    margin: 0 auto;

    & > * {
      margin-bottom: 1rem;
    }
  }

  .headline__container > * {
    margin-bottom: 2rem;
  }

  &.global-partner {
    > * {
      color: #fff;
    }
  }
}

.global-logos {
  padding: 0 2rem;
  text-align: center;
  overflow: auto;

  ul {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 100px));
    grid-gap: 3rem;
    justify-content: center;
    padding-left: 0;
    
    @include MQ(sm) {
      grid-template-columns: repeat(auto-fill, minmax(100px, 150px));
    }

    img {
      max-width: 100%;
      max-height: 100px;
      margin: 0 auto;
    }
  }

  li {
    display: grid;
    align-items: center;
  }
}

// programs
.join-programs {
  background-color: #f0f0f0;
  padding: 8rem 0 4rem 0;
  
  @include MQ(sm) {
    padding: 8rem 0;
  }

  .slick-track {
    padding: 1em 0;
  }

  .programs-sample-container {
    width: 96%;
    max-width: 1440px;
    margin: 0 auto;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }

  .programs-sample {
    max-width: 700px;
    // position: absolute;
    right: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
}

.program-item__container {
  .wide-img, video {
    width: 100%;
  }

  .program-icon {
    height: 30px;
  }
}

.initiatives {
  @include MQ(md) {
    .flex-row {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  
    .program-item {
      max-width: 50%;
    }

  }
}

// criteria
.join-criteria {
  position: relative;
  padding: 4rem 0;
  overflow: hidden;

  &.join-demo {
    // background-image: url("https://picsum.photos/1500/800");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: #fff;
  }
  
  @include MQ(sm) {
    padding: 8rem 0;
  }

}

.triangle {
  clip-path: polygon(100% 0, 0 0, 50% 18%);
  position: absolute;
  top: 0;
  left: 0;
  height: 300px;
  width: 100%;
  background-color: #f0f0f0;
  
  &.triangle-black {
    background-color: $black;
  }
}

.criteria__container {
  position: relative;

  .headline {
    text-align: left;
    margin: 2rem auto;
  }
  
  h4 {
    text-transform: none;
    
    &> {
      margin-bottom: 2rem;
    }
  }

  .visual {
    width: 65%;
    margin: 0 auto;

    @include MQ(md) {
      width: 100%;
    }
    img {
      width: 100%;
    }
  }
  

  ul {
    list-style: none;
  }

  li {
    position: relative;
    margin: 1rem 0;

    &:before {
      content: '';
      position: absolute;
      top: 3px;
      left: -35px;
      height: 25px;
      width: 25px;
      background-image: url('/uploads/check-blue.svg');
      background-size: cover;
    }
  }
  
  
}
.join-demo li:before {
  background-image: url('/uploads/check-white.svg');
}

// CTA
.join-cta {
  background-color: $black;
  color: #fff;
  text-align: center;
  .button {
    // display: block;
    display: inline-block;
    width: 100%;
    max-width: 200px;
    text-align: center;
  }
}
.team-cta {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // grid-gap: 4rem;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 2rem;

  @include MQ(sm) {
    justify-content: center;
  }
}

.team-person {
  position: relative;
  display: grid;
  grid-template-columns: 30% auto;
  grid-gap: 1rem;
  align-items: center;
  width: 100%;
  padding: 1rem 0.5rem;
  
  @include MQ(sm) {
    width: 50%;
  }
}

.team-person__photo {
  width: 90px;
  
  @include MQ(md) {
    width: 130px;
  }
  img {
    width: 100%;
    border-radius: 100%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}

// pricing
.join-pricing {
  background-color: $black;
}

.pricing-container {

  @include MQ(sm) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    justify-content: space-around;
    align-content: center;
    grid-gap: 10%;
    
    &.pricing-container-partner {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
  }

}

.pricing-box {
  margin: 1rem 0;
  align-content: flex-start;
  // background-color: #f0f0f0;
  
  position: relative;
  display: grid;
  z-index: 20;
  // box-shadow: 0 2px 35px -10px rgba(0, 0, 0, 0.5);
  text-align: center;
  padding: 0;

  &:before {
    content:"";
    position:absolute;
    width: 95%;
    height: 100%;
    right: -0.5rem;
    top: 0.5rem;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='3' height='3'%3E%3Ccircle cx='2.5' cy='2.5' r='0.9' fill='%231C5371'/%3E%3C/svg%3E") repeat bottom right;
    z-index: 0;
    
  }

  .price > * {
    display: inline;
  }

  &:nth-child(1) {
    .price h2, .price h3 {
      border-bottom: 2px solid $brand-yellow;
    }
  }

  &:nth-child(2) {
    .price h2, .price h3 {
      border-bottom: 2px solid $light-blue;
    }
  }
  
  &:nth-child(3) {
    .price h2, .price h3 {
      border-bottom: 2px solid $brand-yellow;
    }
  }

  &:nth-child(4) {
    .price h2, .price h3 {
      border-bottom: 2px solid $blue;
    }
  }

  &.blue-dots {
    &:before {
      background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='3' height='3'%3E%3Ccircle cx='2.5' cy='2.5' r='0.9' fill='%231C5371'/%3E%3C/svg%3E") repeat bottom right;
    }
  }
}

.column_price, .price {
  .border-yellow {
    border-color: $brand-yellow !important;
  }

  .border-light-blue {
    border-color: $light-blue !important;
  }
}


.pricing-box-inner {
  position: relative;
  // display: grid;
  z-index: 20;
  padding: 1rem;
  background-color: #fff;
  min-height: 14rem;
  
  &.short {
    min-height: 11rem;

  }
}

.price h2 {
  display: inline-block;
}

.price-description {
  margin: 1rem 0;
}

.pricing-container {
  @include MQ(sm) {
    .price {
      min-height: 4rem;
    }
  
    .price-level {
      min-height: 17rem;
    }
  }
  @include MQ(md) {
    .price {
      min-height: initial;
    }
  
    .price-level {
      min-height: 10rem;
    }
  }
}



// contact modal
.wFormContainer {
  position: relative;
  z-index: 300;
  width: 94%;

  form {
    display: block;
  }

  
  @include MQ(md) {
    .wForm {
      padding: 1rem 3rem !important;
    }
  
    .field-container-D {
      margin-bottom: 1rem !important;
      margin-right: 1rem;
    }
    #tfa_4-D, #tfa_3-D, #tfa_7-D, #tfa_6-D, #tfa_17-D, #tfa_2-D, #tfa_18-D, #tfa_20-D {
      display: inline-block;
    }

  }

}

#himss-login-digital-startup-bootcamp-page {
  .wFormContainer {
    margin: 0 !important;
    .wForm {
      padding: 0 !important;
    }
  }

  .actions {
    margin: 0 !important;
    padding: 0 !important;
  }

  .field-container-D {
    margin-bottom: 0 !important;
  }

  .primaryAction {
    margin-top: 1em !important;
  }
}

.comparison-component {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
}

.comparison-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0;
  margin: 0 auto;
  
  @include MQ(md) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.column_list {
  border-top: 1px solid rgba(0, 0, 0, 0.25);

  li {
    background-color: #fff;
    padding: 10px;
    height: 3em;

    &:nth-child(2n) {
      background-color: #f0f0f0;
    }
  }
}

.column_label {
  @include MQ(md) {
    min-height: 200px;
  }

  @include MQ(l) {
    min-height: 160px;
  }
}

.column_price {
  span {
    margin-left: 0.5rem;
  }

  .price-number {
    border-bottom: 2px solid transparent;
  }
  
  > * {
    display: inline;
    line-height: 20px;
  }
}

.column_cta {
  position: relative;
  z-index: 20;

  a {
    color: #000;
    text-decoration: underline;
  }
}

.column_one {
  position: relative;

  @include MQ(md) {
    border-right: 1px solid rgba(0, 0, 0, 0.25);
  }

  li {
    z-index: 20;
    position: relative;
  }
}

// .column_two {
//   @include MQ(md) {
//     border-left: 1px solid rgba(0, 0, 0, 0.25);
//   }
// }

.join-proof {
  .join-case-studies {
    background-color: $black;
  }
  .join-member-stories {
    margin-top: 0;
    margin-bottom: -9rem;
  }
}