footer {
  background-color: $black;
  color: #fff;
  position: relative;
  padding-bottom: 4rem;
  
  @include MQ(md) {
    padding-bottom: 0;
  }

  div.wrap {
    @include display-flex;
    @include justify-content(space-between);
    @include flex-direction(column);
    padding: $gutter_width*2 0px;

    @include MQ(md) {
      @include flex-direction(row);
    }
  }


  div.logo {
    @include display-flex;
    flex-grow: 1;
  }

  nav {
    @include display-flex;

    a {
      // font-size: .8rem;
      padding: $gutter_width/2 0px;
      opacity: .75;
      @include transition(all 300ms ease);
      font-family: "Visby";
      font-weight: 300;
      width: 50%;

      &:hover {
        opacity: 1;
      }

      @include MQ(sm) {
        padding: $gutter_width/4;
        width: auto;
      }
    }

    &.sitemap {
      @include flex-direction(column);
      gap: 1rem;
      width: 100%;

      @include MQ(sm) {
        @include flex-wrap(wrap);
        max-height: 292px;
        flex-grow: 5;
        width: auto;

      }
    }

    &.social {
      width: 100%;
      max-width: 360px;
      flex-grow: 1;
      padding: 0px;
      // display: block;
      @include display-flex;

      a {
        text-align: center;
      }

      @include MQ(sm) {
        justify-content: space-around;
      }
    }
  }

  .newsletter {
    @include display-flex;
    @include flex-direction(column);
    @include align-items(flex-start);
    flex-grow: 1;

    .clear {
      display: inline-block;
    }

    #mc_embed_signup {
      // .button {
      //   line-height: 40px !important;
      // }

      input[type="email"] {
        padding: 0.45em;
        border: none;
        border-radius: 0;
        width: 60%;
      }
      
      input[type="submit"] {
        padding: 0 30px !important;
        border: none;
        // vertical-align: middle !important;
        margin-top: 2px;
      }
    }
  }

  div.copyright {
    display: block;
    width: 100%;
    text-align: center;

    p {
      // line-height: 1.1;
      // font-size: .7rem;
    }
  }

  a.getHealth {
    position: absolute;
    bottom: 5px;
    right: 10px;

    img {
      max-width: 150px;
    }

    @include MQ(md) {
      bottom: 0px;
      right: $gutter_width;
    }
  }
}


.footer-list {
  list-style: none;
  padding-left: 0;

  h5 {
    padding-left: 5px;
    font-family: "Visby";
  }
}