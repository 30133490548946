form {
  @include display-flex;
  @include flex-direction(column);

  div.inputGroup {
    @include display-flex;
    @include flex-direction(column);
  }

  div.buttonGroup {
    @include display-flex;
    @include flex-direction(column);
    padding: $gutter_width;
  }

  input, textarea {
    border: 0px;
    border-bottom: 1px solid #a4a5a6;
    padding: $gutter_width/2 0px;
    outline: none;
    font-family: "Visby";
    font-weight: 300;
    // font-size: .9rem;
    letter-spacing: 1px;

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      opacity: .75;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      opacity: .75;
    }
    :-ms-input-placeholder { /* IE 10+ */
      opacity: .75;
    }
    :-moz-placeholder { /* Firefox 18- */
      opacity: .75;
    }
  }

  textarea {
    resize: vertical;
  }

  label {
    font-family: "Visby";
    // font-size: .8rem;
  }

  button {
    width: percentage(2/3);
    background-color: #fac012;
    border: none;
    display: block;
    margin: 0 auto;
    padding: $gutter_width;
    color: #fff;
    font-family: "Visby";
    font-weight: bold;
    cursor: pointer;
  }
}

section.formPage {
  @include display-flex;
}

div.formPane {
  padding-top: 150px;
  width: 100%;
  @include display-flex;
  @include flex-direction(column);

  img {
    display: block;
    margin: 0 auto;
    margin-bottom: $gutter_width;
  }

  p.formIntro {
    text-align: center;
  }

  @include MQ(md) {
    width: percentage(1/3);
  }
}

div.imagePane {
  display: none;

  @include MQ(md) {
    @include display-flex;
    width: percentage(2/3);
    position: relative;
    @include bg-size(cover);

    div.copy {
      text-align: center;
      position: absolute;
      @include center(xy);
      color: #fff;
      width: 100%;
    }

    img {
      max-width: 100%;
    }
  }
}

div.contactInfo {
  p, a {
    // font-size: .8rem;
    font-family: "Visby";
    // line-height: 1.6;
    color: $light-gray;
  }

  a {
    color: $brand-yellow;
    text-decoration: underline;
  }
}
