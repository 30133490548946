.blog-newsletter-signup {

  #mc_embed_signup {
    margin-top: 1rem;
  }

  #mc_embed_signup_scroll {
    input[type="email"] {
      border: 1px solid $brand-gray;
      width: 100%;
      padding-left: 10px;
    }

    input[type="submit"] {
      width: 100%;
      margin-top: 0.5rem;
      padding: 0 30px !important;
    }
  }
}

aside {
  display: none;
  position: sticky;
  vertical-align: top;
  top: 157px;
  width: 30%;
  height: 100%;
  padding: 8rem 2rem;
  margin-bottom: 3.6rem;

  @include MQ(l) {
    display: block;
    float: left;
  }
}

.aside-break {
  border: 1px solid $blue;
  margin: 1em 0;
  width: 8em;

  .post-ab & {
    border-color: $aqua;
  }
}

.aside__container {
  padding: 2rem 2rem 4rem 2rem;
  background-color: whitesmoke;
  margin-right: 0;
  border: 1px solid $light-gray;

  @include MQ(l) {
    margin-left: auto;
    max-width: 350px;
  }

  &.mobile-ab {
    @include MQ(l) {
      display: none;
    }
  }
}

.category-dropdown {
  display: inline-block;
  padding-bottom: 2rem;
  ;
  position: relative;

  @include MQ(md) {
    float: right;
  }

  .button {
    padding: 0 30px !important;
  }
}

.dropdown-menu {
  position: absolute;
  display: none;
  z-index: 500;
  background-color: #fff;
  padding: 0.5rem 0;
  border: 1px solid $brand-gray;
  width: 100%;


  .dropdown-item {
    display: block;
    padding: 0.5rem;
    text-transform: uppercase;

    &.active {
      color: $blue;
      text-decoration: underline;
    }
  }
}

.dropdown.open {
  .dropdown-menu {
    display: block;
  }
}


section.blog {
  // padding-top: 10rem;


  div.header {
    padding-bottom: 1rem;
    margin-bottom: 1.2rem;
    max-width: 900px;

    h1 {
      border-bottom: 1px solid $light-gray;
      padding-bottom: $gutter_width;
    }
  }

  .blog-main-column {
    padding: 0 2rem;
    // padding-left: 30%;

    @include MQ(l) {
      padding: 0 2rem 0 30%;
      // display: inline-block;
      // float: right;
      // width: calc(70% - 2rem);
      // padding-right: 2rem;
    }

  }

  .blog-main-column {
    .bmc-section {
      max-width: 900px;
    }
  }

  div.postTile {
    // min-height: 15em;
    border-bottom: 1px solid $light-gray;

    h2 {
      color: #000;
    }

    @include MQ(md) {
      &.medium {
        grid-column: span 2;
      }

      &.large {
        grid-column: 1 / 4;
      }

      &.small+.medium,
      &.medium+.small {
        .postLink {
          border-left: 1px solid $light-gray;
        }
      }

      &.small+.medium+.small,
      &.medium+.small+.medium {
        .postLink {
          border-left: none;
        }
      }

      &.small+.medium+.small+.medium,
      &.medium+.small+.medium+.small {
        .postLink {
          border-left: 1px solid $light-gray;
          border-right: none;
        }
      }
    }


  }

  a.postLink {
    height: 100%;
  }

  // .case-study {
  //   display: grid;
  //   grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  //   grid-gap: 0.5em 2em;

  //   margin: 2em 0;
  //   padding: 2em 0;
  //   border-top: 1px solid black;
  //   border-bottom: 1px solid black;

  //   p {
  //     font-style: italic;
  //   }
  // }
}

.postBlock {
  background: rgb(245, 245, 245);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 1rem;
  text-align: left;
  position: relative;
  margin: 0 auto 10rem 0;
  overflow: hidden;
  // clear: both;
  width: 100%;
  max-width: 900px;

  @include MQ(md) {
    padding: 3rem 5rem;
  }

  &>* {
    z-index: 100;
    position: relative;
  }

  .postBlock__container {
    max-width: 650px;
    margin: 0 auto;

    .postBlock__link {
      display: block;
      margin-top: 1rem;

    }
  }

  .post__author-image {
    max-width: 75px;
    border-radius: 100%;
    margin: 0 auto;
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translate(-50%);
  }

  .blog-category {
    // padding: 0px $gutter_width/2;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "Visby";
    // font-weight: bold;
    // font-size: .7rem;
    // line-height: 1.4;
    color: #fff;
    border-bottom: 2px solid #fff;
    display: inline;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
  }

  .postTitle {
    // text-align: center;
    font-weight: 900;
    max-width: 650px;
    margin: 0 auto;
    color: #fff;
  }

  .postBlock-meta {
    margin-bottom: 2rem;

    p {
      color: #f0f0f0;
      font-family: 'Visby';
      font-weight: 300;
    }

    a {
      color: $aqua;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: -30px;
    left: 0;
    width: 100%;
    height: 350px;
    background: $light-blue;
    transform: skewY(-3deg);
    opacity: 1;
    z-index: 0;
  }

  &.matter-news-header:before {
    background: $blue;
    transform: skewY(3deg);
  }

  &.insights-header::before {
    background: $aqua;
    transform: skewY(3deg);
  }

  &.press-release-header::before {
    background: $brand-gray;
    // transform: skewY(3deg);
  }

  &.community-header::before {
    background: $black;
    // transform: skewY(3deg);
  }

  &.no-preview-image {
    &:before {
      // transform: skewY(2deg);
      height: 270px;
    }

    .postPreview {
      margin-top: 6rem;
    }

  }
}

article:last-of-type {
  margin-bottom: 5rem;
}

div.previewImage {
  width: 100%;

  img {
    width: 100%;
  }
}

// .quote-hero {
//   background-color: $lightest-gray;
// }

// .case-study {
//   display: grid;
//   grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
//   grid-gap: 0.5em 2em;

//   margin: 2em 0;
//   padding: 2em 0;
//   border-top: 1px solid black;
//   border-bottom: 1px solid black;

//   p {
//     font-style: italic;
//   }
// } 

div.postPreview {
  text-align: left;
  margin-top: 1rem;

  * {
    color: #000;
  }

  p {
    color: #000;
    // font-family: "Visby";
    // font-weight: 500;
    -webkit-font-smoothing: auto;

    &:first-of-type {
      margin-bottom: 1rem;
    }
  }

  a {
    color: $blue;
  }

  .cappedLink {
    margin-top: 2rem;
    display: flex;
    align-items: center;

    img {
      margin-right: 0.5rem;
    }
  }
}

.pagination {
  padding-bottom: 5rem;

  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 250px;
    margin: 0 auto;
  }

  li {
    color: $blue;
  }

  .fa {
    vertical-align: bottom;
  }
}

.post {
  // padding: 170px 0px 100px 0;
  width: 100%;

  @include MQ(l) {
    display: inline-block;
    width: 69%;
  }

  &.job-post,
  &.challenge-post,
  &.live-post,
  &.case-study {
    display: block;
    width: 100%;
    max-width: 1000px;
  }

  // &.case-study {
  //   padding: 50px 0 100px;
  // }

  margin: 0 auto;

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    padding: $gutter_width/2 0;
    margin-bottom: 0;
  }

  p,
  a:not(.button),
  b,
  ol,
  ul {
    // font-family: "Visby";
    font-weight: 500;
    // color: #000;
    -webkit-font-smoothing: auto;
    // letter-spacing: 1px;
  }

  img {
    max-width: 100%;
  }

  // li {
  //   font-family: "Visby";
  // }
}

.post-body-copy p {
  font-weight: 500;
  color: #000;
  -webkit-font-smoothing: auto;
}

.post__wrapper {
  max-width: 1000px;
  margin: 0 auto;

  @include MQ(md) {
    width: percentage(4/5);
  }

  @include MQ(l) {
    margin-left: 0;
  }

  &.basic {
    margin: 0 auto;
  }
}

.postHero {
  // max-width: 1000px;
  margin-bottom: $gutter_width/2;

  img {
    width: 100%;
  }


}

.case-study-details {
  position: relative;
  z-index: 1;
  max-width: 350px;

  .csd-container {
    background-color: $lightest-gray;
    color: #000;
    padding: 0.5em;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      right: -0.5rem;
      top: 0.5rem;
      background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='3' height='3'%3E%3Ccircle cx='2.5' cy='2.5' r='0.9' fill='%23307A83'/%3E%3C/svg%3E") repeat bottom right;
      z-index: -1;
    }

    h5,
    p {
      padding: 0;
    }
  }

  h5 {
    border-bottom: 2px dotted $aqua;
    display: inline;
  }

  // td {
  //   width: 225px;
  //   vertical-align: top;
  // }
}

.case-study-detail-element {
  max-width: 150px;
}

.read_more_post {
  border: 1px solid $light-gray;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16), 0 2px 6px rgba(0, 0, 0, 0.23);
  display: grid;
  grid-template-columns: 1fr;

  @include MQ(md) {
    grid-template-columns: 320px 1fr;
  }

  // @include MQ(md) {
  //   display: flex;
  // }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    // max-width: 320px;
    // flex: 1;
  }

  // .post_meta {
  //   // flex: 2;
  // }
}

.postContent {

  a:not(.button),
  a:visited:not(.button) {
    color: $blue;
    text-decoration: underline;
    font-weight: normal;
  }

  .module__description[data-text-color] a {
    color: inherit;
  }

  p {
    padding: $gutter_width/2 0;
  }

  img,
  iframe {
    max-width: 100%;
    // max-height: 1000px;
    display: block;
    margin: 0 auto;
  }

  blockquote {
    color: $brand-gray;
    text-align: center;
  }

  &:not(.liveContent) iframe {
    margin: $gutter_width;
  }

  twitter-widget {
    margin: 0 auto;
  }

  h4 {
    color: $blue;
    font-style: italic;

    @include MQ(md) {
      width: 110%;
      margin-left: -3%;
    }
  }

  // @include MQ(md) {
  //   width: percentage(4/5);
  //   max-width: 1000px;
  // }
}

div.postData {
  @include display-flex;

  p {
    padding: 0px $gutter_width/2;
    text-transform: uppercase;
    letter-spacing: 1px;
    // font-family: "Visby";
    font-weight: 300;
    color: #9B9B9B;
    // font-size: .7rem;
    // line-height: 1.4;

    &:first-of-type {
      color: $blue;
    }
  }

}

.go-back {
  padding-bottom: 2rem;
}

div.postHeader div.postData {
  @include flex-direction(column-reverse);

  @include MQ(md) {
    @include align-items(flex-end);
  }

  @include MQ(l) {
    @include flex-direction(row);

  }
}


div.postHeader {
  @include display-flex;
  @include flex-direction(column-reverse);
  // width: 100%;

  @include MQ(md) {
    @include flex-direction(row);
    @include align-items(center);
    @include justify-content(space-between);
  }
}

div.postAuthor {
  @include display-flex;
  @include align-items(center);

  img {
    width: 60px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  p,
  a {
    // font-size: .8rem;
    line-height: 1.2;
  }

  a,
  a:visited {
    // font-family: "Visby";
    font-weight: 400;
    color: $blue;

  }

  p {
    padding: 0px $gutter_width/2;
  }

}

.related-separator {
  width: percentage(3/4);
  max-width: 1000px;
  margin: 5rem 0;
}

.related-blogs-header {
  margin-bottom: 1rem;
  padding-top: 5rem;
}

.related-blogs {
  padding: 0 10px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;

  @include MQ(md) {
    grid-template-columns: 1fr 1fr;
  }

  h5 {
    padding: 0;
  }
}

// categories
.blog-footer {
  @include MQ(sm) {
    display: grid;
    grid-template-columns: 250px 250px;
    justify-content: space-between;
  }


  border-top: 1px solid $brand-gray;
  border-bottom: 1px solid $brand-gray;
  padding: 3rem 0;
  margin-top: 3rem;
}

.categories-and-tags {
  // padding-top: 3rem;

  .categories {

    @include MQ(sm) {
      text-align: right;
    }


    .cat {
      background: $blue;
    }
  }


  h5 {
    display: inline;
  }

  .cat {
    display: inline;
    background: $brand-gray;
    color: #fff;
    border-radius: 10px;
    text-transform: uppercase;
    font-size: 11px;
    line-height: 2.5rem;

    a {
      color: #fff;
      white-space: nowrap;
    }
  }
}


// stories that matter
.stm__container {

  @include MQ(md) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
  }
}

.stm-hero {
  color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 80% center;

  .container {
    padding-top: 8rem;
    padding-bottom: 5rem;

    @include MQ(md) {
      padding-bottom: 12rem;
    }

    h2 {
      text-align: center;
    }

    img {
      width: 100%;

      @include MQ(md) {
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.16), 0 6px 12px rgba(0, 0, 0, 0.23);
      }
    }
  }

  .headline {
    margin: 0 auto;
    position: relative;
  }
}

.stm-section {
  position: relative;
  padding: 3rem 0;

  @include MQ(md) {
    padding: 4rem 0;

  }

  .gray-callout__box {
    background: transparent;
  }
}

.stm-intro {
  padding-top: 0;

  #the-pf-perspectives-library-page & {
    // padding-top: 100px;

    .breadcrumbs {
      top: -20px;
      left: 0;
      max-width: 800px;
      margin: 0 auto;

      @include MQ(md) {
        top: -40px;
      }

      ol {
        padding-left: 0;
      }
    }
  }


  &:before {
    content: '';
    position: absolute;
    top: -2%;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f0f0f0;
    transform: skewY(-2deg);
    opacity: 1;
    z-index: 0;

    @include MQ(md) {
      height: 110%;
      top: -10%;
    }
  }

  * {
    z-index: 100;
    position: relative;
  }
}

.stm-story__container {
  padding-bottom: 3rem;
}

.stm-description {
  max-width: 800px;
  margin: 0 auto;

  p {
    padding: 10px 0;
  }

  a {
    text-decoration: underline;
  }
}


.search-container {
  display: grid;
  grid-template-columns: 4fr 1fr;
  gap: 5px;

  input[type="search"] {
    line-height: 2;
    font-size: 1rem !important;
    border-radius: none !important;

    @include MQ(md) {
      min-width: 15rem;
    }
  }

  button {
    font-size: 1rem !important;
    padding: 0 30px !important;

    img {
      display: flex;
      margin: 0.5rem 0;
    }
  }
}

.unibox__selectable-title {
  font-weight: normal !important;
}

.unibox-n-section {
  margin-bottom: 1rem !important;
  border-bottom: 1px solid $light-gray;
}

.unibox__show-all-text {
  font-size: 0.8rem;
}

#ss360-custom-searchbutton {
  display: flex !important;
}

.unibox__watermark img {
  opacity: 0;
}