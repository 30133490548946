// new home styles
.secondary-nav {
  display: grid;
  position: relative;
  z-index: 500;
  justify-content: end;
  background-color: $black;
  color: #fff;
  right: 0;
  transition: right 300ms ease;
  
  &.sn-b {
    position: fixed;
    width: 100%;
    top: 0;
    
    &.promo-bar-is-open {
      top: 70px;
      
      @include MQ(md) {
        top: 45px;
      }
    }
  }

  .menu-open & {
    right: 250px;
  }

  a {
    padding: 0.4rem 0;
    font-size: 0.8rem;
    font-weight: 400;
    opacity: 0.8;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 0.5rem;
    transition: opacity 300ms ease;
    
    &:hover {
      opacity: 1;
    }
  }

  svg, img {
    max-width: 1rem;
    max-height: 1rem;
    align-self: center;
    width: 100%;
  }

  path {
    fill: #fff;
    stroke: #fff;
  }
}

.home {
  section {
    position: relative;

    &.home-section {
      padding: 4em 0;
      
      @include MQ(md) {
        padding: 8em 0;
      }
    }
  }

  .hs-header {
    text-align: center;
    padding-bottom: 4rem;
  }
}

.home-break {
  height: 3em;
  border: none;
  background: #fff;
  opacity: 0.7;

  &.hb-grey {
    background: #f0f0f0;
  }
}

.home-container {
  width: 94%;
  max-width: 1200px;
  margin: 0 auto;
}

.home-hero {
  display: grid;
  min-height: 550px;
  height: 100%;
  grid-template-columns: 300px;
  // grid-template-columns: repeat(auto-fit, minmax(300px, 500px));
  justify-content: center;
  align-items: center;
  grid-gap: 5em;
  padding-bottom: 2em;
  z-index: 100;
  color: #fff;
  
  @include MQ(md) {
    grid-template-columns: 600px;
    padding-top: 13em;
    // padding-left: 5em;
  }

  &:before {
    content: '';
    position: absolute;
    top: -20%;
    left: 0;
    width: 100%;
    height: 113%;
    background: linear-gradient(15deg, $black 25%, darken($black, 12%) 100%);
    transform: skewY(-5deg);
    opacity: 1;
    z-index: 0;
    
    @include MQ(md) {
      top: -30%;
      height: 135%;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: -17%;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 250px 200px 0;
    border-color: transparent $light-blue transparent transparent;
    opacity: 1;
    z-index: 510;
    
    @include MQ(md) {
      border-width: 0 500px 500px 0;
    }
  }

  h4 {
    text-transform: none;
    margin-top: 1em;
  }
}

.hero {
  .watermark {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    
    @include MQ(md) {
      left: 25%;
      width: 500px
    }
  }
}

.sliding-text .slick-slide {
  height: 108px !important;

  @include MQ(l) {
    height: auto !important;
  }
  // h1 {
  //   margin-bottom: 0;
  //   line-height: 1.8;
  // }
}

.hero__content {
  h1 {
    margin-bottom: 0;
  }
}


.split {
  z-index: 100;

  img {
    width: 100%;
  }

  &.split-right {
    .hh-image {
      width: 50%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.background-slider {
  z-index: 550;
  position: absolute;
  top: 15em;
  right: 5em;
  width: 40%;
  height: 700px;
}

.hero__content {
  display: grid;
  grid-template-columns: 1fr;
  z-index: 100;
  opacity: 0;
  transition: opacity 1500ms ease-in;
  // margin-top: 8rem;
  
  @include MQ(md) {
    margin-top: 0;
  }

  &.shown {
    opacity: 1;
  }

  a {
    // width: 200px;
    text-align: center;
    text-transform: uppercase;
    color: darken(#f0f0f0, 15%);
  }
}

.hh-community {
  z-index: 500;
  text-align: center;
  margin-top: 3em;

  h5 {
    text-align: left;
    opacity: 0.6;
    padding-bottom: 1em;
  }

  ul {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50px, 80px));
    grid-gap: 2em;
    align-items: center;
    justify-content: space-between;

    img {
      width: 100%;
      filter: gray;
      -webkit-filter: grayscale(1);
      filter: grayscale(1);
      opacity: 0.7;
    }
  }

}

.home-about {
  min-height: 100%;

  &:before {
    content: '';
    position: absolute;
    top: -20%;
    left: 0;
    width: 100%;
    height: 120%;
    background: #f0f0f0;
    transform: skewY(2deg);
    opacity: 1;
    z-index: 0;
  }
}

.about__title {
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  z-index: 100;

  h2 {
    margin-bottom: 1em;
    text-align: center;
    overflow: auto;
  }
}

.about__aspects {
  position: relative;
  display: grid;
  grid-gap: 2em;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

  padding: 3em 0;
  z-index: 100;
}

.about__aspect {
  min-height: 200px;
  text-align: center;
}

.aspect__image {
  width: 100px;
  height: 100px;
  display: block;
  border-radius: 50%;
  text-align: center;
  // background: $brand-gray;
  margin: 0 auto;

  img, svg {
    overflow: inherit;
    margin-top: 13%;
    width: 75%;
    max-height: 75%;
    stroke: $blue;
    fill: $blue;
  }

  &.ai_150 {
    width: 150px;
    height: 150px;
  }

}

.aspect__title {
  display: inline-block;
  vertical-align: top;
  max-width: 75%;
  text-transform: uppercase;
  margin: 1em 0;
}

.home-community {
  padding-top: 3em;
  position: relative;
  z-index: 100;

  span.button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0%);
    opacity: 0;
    transition: opacity 1200ms cubic-bezier(.19,1,.22,1), background-color;
    z-index: 100;
    background-color: $aqua;
    text-transform: uppercase;
  }
  
  .community-reel {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 120px));
    justify-content: space-around;
    grid-row-gap: 3em;
    max-width: 1200px;
    margin: 0 auto;
  
    transition: filter 1s cubic-bezier(.19,1,.22,1);
    filter: blur(0);    
  
    li {
      display: grid;
      align-items: center;
      
      img {
        max-width: 100%;
        max-height: 60px;
        margin: 0 auto;
      }
    }
  }

  @include MQ(md) {
    a:hover {
      .community-reel {
        filter: blur(7px);
      }
  
      span.button {
        opacity: 1;
      }
    }
  }
}

.stories__container {
  display: grid;
  grid-template-areas:
    "featured"
    "side1"
    "side2";
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-gap: 2em 5em;
  @extend .element;
  
  @include MQ(sm) {
    grid-template-areas:
      "featured featured"
      "side1 side2";
    grid-template-columns: 1fr 1fr;
  }
  
  @include MQ(md) {
    grid-template-areas:
    "featured featured side1"
    "featured featured side2";
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.story {

  .story__date {
    font-weight: 300;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $brand-gray;
  }

  .story-break {
    border: 1px solid $light-blue;
    margin: 1em 0;
    width: 10rem;
    transition: width 300ms ease;
  }

  &.story-small {
    @include MQ(sm) {
      min-height: 175px;
    }
  }

  &:hover {
    .story-break {
      width: 12rem;
    }
  }
}

// .stories__container {
//   .story {
    
//   }
// }

.story-featured {
  grid-area: featured;
}

.side-1 {
  grid-area: side-1;
}

.side-2 {
  grid-area: side-2;
}


.hs-cta {
  text-align: center;
  padding: 2em 0;
  color: $blue;
}

.home-events {
  background-color: #f0f0f0;

  .he-header {
    text-align: center;
    padding-bottom: 2rem;
  }

  .he-list__header {
    text-align: center;
    margin: 4rem auto 1rem;
    padding: 3rem 0 1rem;

    p {
      max-width: 600px;
      margin: 0 auto;
    }
  }

  .slick-list {
    height: 100% !important;
  }

  .slick-track {
    padding: 1em 0;
  }
}

.tag_slider {
  .slick-track {
    padding: 1em 0;
  }
}

.he-slide {
  padding: 0 1em;
  outline: none;
  
  .event-meta {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    padding: 2em 2em 3em;
    min-height: 230px;
    position: relative;
    transition: box-shadow 350ms ease, border-color 350ms ease;

    background-color: #fafafa;

    &:hover {
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.16), 0 6px 12px rgba(0, 0, 0, 0.23);
    }

    &.em-last {
      display: grid;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .event-date {
      position: absolute;
      left: 2em;
      bottom: 2em;
      padding-top: 1rem;
      border-top: 2px solid $red;
    }
  }
}

.home-connect {
  background-color: #f0f0f0;
  padding: 6em 0 !important;

  &>div {
    align-items: normal !important;
    padding-top: 8em;
  }

  .hc-social {
    .social {
      justify-content: space-evenly;
      max-width: 400px;
      margin: 1rem auto;

      a {
        // font-size: 2.5rem;
      }
    }
  }
  
  .home-container {
    display: grid;
    // grid-template-columns: minmax(300px, 1fr) minmax(300px, 1fr);
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    align-items: center;
    position: relative;
    color: #fff;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: -20%;
    left: 0;
    width: 100%;
    height: 105%;
    background: linear-gradient(-5deg, $black 25%, darken($black, 7%) 100%);
    transform: skewY(5deg);
    opacity: 1;
    z-index: 0;
  }

}

.hc-signup__form #mc_embed_signup {
  clear: left;

  form {
    text-align: center;
    padding: 10px 0 10px 0;
  }
  
  label {
    padding-bottom: 10px;
    font-weight: bold;
    display: inline-block;
    max-width: 420px;
    margin: 0 auto 1em;
  }

  input.email {
    font-family: 'Visby';
    font-weight: 300;
    border: 1px solid #ABB0B2;
    color: #343434;
    box-sizing: border-box;
    padding: 0px 0.4em;
    display: inline-block;
    margin: 0;
    max-width: 350px;
    width: 100%;
    vertical-align: top;

    background-color: #f0f0f0;
    border-color: #f0f0f0;
    border-width: 2px;
    height: 42px;
  }

  .button {
    border: none;
    letter-spacing: .03em;
    color: #fff;
    box-sizing: border-box;
    // line-height: 42px;
    padding: 0 18px;
    display: inline-block;
    margin: 0;
    transition: all 0.23s ease-in-out 0s;
    height: 42px;
    text-transform: uppercase;
    width: 100%;

    &:hover {
      background-color: darken($brand-yellow, 10%);
    }
  }

  .clear {
    display: inline-block;
    margin: 18px 0;
    width: 100%;
    max-width: 350px;

    @include MQ(l) {
      margin: 0;
      width: initial;
    }
  }
}

.hc-intro, .hc-signup {
  padding: 1em;

  @include MQ(md) {
    padding: 2em;
  }
}

.hc-cta-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 250px;
  grid-gap: 1em;
}

.hc-signup {

  nav.social {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: space-between;

    a {
      display: inline-block;
      text-align: center;
    }
  }
}

.hc-cta__logo {
  padding: 3em;
  display: grid;
  align-items: center;

  img {
    width: 100%;
  }
}

.new-home footer {
  .social, .newsletter {
    opacity: 0;
    pointer-events: none;
  }
} 