.bg_light-gray {
  background-color: #f0f0f0 !important;
}

.bg_white {
  background-color: #fff !important;
}

.yir {
  .featured-bullets {
    max-width: 620px;
    margin: 0 auto;

    ul {
      width: fit-content;
      margin: 0 auto;
      text-align: left;
    }
  
    li {
      span {
        display: inline-block;
      }
  
      &::marker {
        color: $blue;
      }
    }

  }

  .slider-box {
    max-width: 700px;
    margin: 0 auto;
    
    img {
      max-width: 60%;
      width: auto;
      max-height: 200px;
      margin: 0 auto;
    }

    .slick-dots {
      bottom: -60px;
    }
  }

  .acquisitions .slider-box {
    border: 4px solid $black;
  }
}

.two-column {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  
  @include MQ(md) {
    grid-template-columns: 1fr 1fr;
  }

  p a {
    text-decoration: underline;
  }

  &.md-reverse {

    @include MQ(md) {
      :first-child {
        order: 2;
      }

      :nth-child(2) {
        order: 1;
      }

    }
  }
}

.color-block {
  padding: 0.5em 0;

  > * {
    margin-bottom: 0;
  }

  sup {
    font-size: 1rem;
    vertical-align: super;
  }

  &.blue {
    background-color: $blue;
    color: #fff;
  }

  &.yellow {
    background-color: $brand-yellow;
  }

  &.aqua {
    background-color: $aqua;
    color: #fff;;
  }

  &.red {
    background-color: $red;
    color: #fff;
  }
}

.back-to-top-fixed {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.show {
    opacity: 1;
  }
  
  svg {
    transform: rotate(-90deg);
    // max-width: 16px;
    height: 40px;
  }

  path {
    fill: #fff;
    stroke: #fff;
  }
}

.back-to-top {
  svg {
    transform: rotate(-90deg);
    max-width: 16px;
  }

  path {
    fill: #fff;
    stroke: #fff;
  }
}

.intro a {
  text-decoration: underline;
}