.birthday-intro-section {
  background-color: $black;
  color: #fff;
}


.bg-img {
  height: 45vh;
  min-height: 500px;
  position: relative;
  display: flex;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .headline {
    color: #fff;
    z-index: 100;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
  }
}


.birthday-hero {
  height: 100vh;
  position: relative;
  overflow: hidden;

  video {
    // width: 100%;
    position: absolute;
    @include center(xy);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
  }

  .content {
    width: 100%;
    position: absolute;
    bottom: 0;
    @include display-flex;
    @include flex-direction(column);
    @include align-items(center);
    
    @include MQ(XS) {
      @include center(xy);
    }

    @include MQ(md) {
      width: percentage(1/2);
      @include display-flex;
      @include flex-direction(column);
    }
  }

  .copy {
    text-align: center;
    padding: 20px 0px;
    color: #fff;
    max-width: 720px;
    
    @include MQ(md) {
      padding: 50px 0px;
    }
  }
}

.birthday-intro-section {
  p:first-of-type:first-letter {
    font-size: 5rem;
    float: left;
    padding-top: 0.4rem;
  }
  p {
    padding-bottom: 1rem;
  }
}

.highlighted-text {
  position: relative;
  color: $blue;
  z-index: 100;
  font-weight: normal;
  
  &:before {
    content: '';
    position: absolute;
    background-color: $brand-yellow;
    opacity: 0.7;
    height: 40%;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: -10;
  }
}


.birthday-page-section .side__right {
  padding-top: 3rem;
}

.switchbacks {
  max-width: 720px;
  margin: 0 auto;
  padding: 2rem 0;
  
  img {
    width: 100%;
    flex: 1;
    max-width: 100px;
  }
  
  .switchback-content {
    flex: 7;
    display: flex;
    align-items: center;
  }
}

.switchback-left, .switchback-right {
  padding-bottom: 2rem;
  display: flex;
  gap: 2rem;
  align-items: center;
}

.birthday-page-section {
  .copy {
    width: 50%;
  }

  .featureB {
    color: initial;
  }

  .feature__description {
    text-align: center;
  }

  &.heroCenter {
    height: 50vh;
  }

  .data-columns {
    display: flex;
    // gap: 2rem;
  }

  .data__point {
    flex: 1;
    padding: 2rem;
    border-right: 1px solid $blue;

    &:last-of-type {
      border: none;
    }
  }

  &.heroCenter {
    .data__stat, .data__point {
      color: #fff;
      border-color: #fff;
    }
  } 
}

.link-container {
  // padding-top: 2rem;
  // padding-bottom: 1rem;

  a {
    text-decoration: underline;
  }
}

.subsection {
  padding: 3rem 0;
}

// testimonials
.testimonial {
  // padding: 2rem;
  // margin: 3rem auto 3rem;
  max-width: 800px;
  border-bottom: 3px dotted $blue;
  border-top: 3px dotted $blue;

  display: grid;
  grid-template-areas: 
          "img img img"
          "copy copy copy"
          "byline byline byline";
  grid-template-columns: 1fr 1fr 1fr;

  @include MQ(sm) {
    grid-template-areas: 
          "img copy"
          "....... byline";
    grid-template-columns: 120px auto;
    grid-gap: 0 2rem;
  }

  p, h4, h5 {
    padding-bottom: 1rem;
    grid-area: copy;
    font-family: "Visby", sans-serif;
  }

  .byline {
    grid-area: byline;
  }

  a {
    color: $blue;

    &:hover {
      text-decoration: underline;
    }
  }

  &.v1b {
    border: none;
  }

  &.v2 {
    border-bottom: 3px solid $blue;
    border-top: 3px solid $blue;
    grid-template-columns: initial;

    @include MQ(sm) {
      grid-template-columns: 2fr 3fr;
    }
  }
}

.testimonial__img {
  grid-area: img;
  position: relative;
  padding: 1rem;
  max-width: 150px;
  justify-self: center;

  &:after {
    content: '';
    position: absolute;
    top: 20px;
    right: 20px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 80px 100px 0;
    border-color: transparent $brand-yellow transparent transparent;
    opacity: 1;
    z-index: 0;
    transform: rotate(-5deg);
  }

  img {
    position: relative;
    border-radius: 50%;
    width: 100%;
    z-index: 100;
  }

  .v2 & {
    max-width: none;
    padding: 0;

    &:after {
      border: none;
    }

    img {
      border-radius: 0;
    }
  }
}

.bulleted-list {
  max-width: 720px;

  ul {
    columns: 2;
  }
}

.single-image {
  text-align: center;
  img {
    width: 100%;
    max-width: 720px;
    margin: 0 auto;
  }
}

.stacked-image-text {
  padding-bottom: 8rem;
}

.thank-you-section {
  background-color: $black;

  * {
    color: #fff;
  }
}

.thanks-container {
  max-width: 800px;
  margin: 0 auto;
}