.copy__container {
  max-width: 580px;
  margin: 0 auto;
}

.community-hero {
  background-color: $lightest-gray;

  &:before {
    content: '';
    position: absolute;
    top: -20%;
    left: 0;
    width: 100%;
    height: 115%;
    background: $blue;
    transform: skewY(-5deg);
    opacity: 1;
    z-index: 50;
  }

}

.community-content.original {
  @include MQ(md) {
    background-image: url("/uploads/matter-network-1.png");
    background-position-x: -10%;
    background-size: 50%;
    overflow: visible;
    background-position-y: 28%;
    background-repeat: no-repeat;
  }
  
  @include MQ(xl) {
    background-position-x: 10%;
    background-size: 40%;
  }
}

.community-graphic {
  @include MQ(md) {
    display: grid;
    grid-template-columns: 3fr 6fr;
    grid-gap: 3rem;
  }
  @include MQ(l) {
    grid-template-columns: 1fr 1fr;
  }
}

.graphic__container {
  img {
    display: block;
    width: 120%;
    margin-left: -10%;
    padding-bottom: 3rem;
    @include MQ(sm) {
      width: 80%;
      margin-left: 10%;
    }
    @include MQ(md) {
      display: none;
    }
  }
}

.community-section {
  background-color: #f0f0f0;
  .section__cta {
    width: 80%;
    max-width: 800px;
    background-color: #fff;
    padding: 2rem;
    margin: 7rem auto 0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}


.community-modal .modal__content {
  width: 50%;
}
