.gray-callout__box {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  background: $lightest-gray;
}

.signup__header {
  max-width: 500px;
  margin: 0 auto;
  text-align: center;

  h4 {
    margin-bottom: 1rem;
  }
}

.signup__form {
  margin: 0 auto 1em;
  text-align: center;
  
  #mc_embed_signup form {
    text-align: center;
    .email, .clear {
      display: inline-block;
    }

    .button {
      text-transform: uppercase;
      padding: 0 30px !important;
    }
  }
}


.gray-callout__box #mc_embed_signup_scroll {
  input[type="email"] {
    padding: 0.45em;
    // border-bottom: none;
    border-radius: 0px !important;
  }
}

.tag {
  // font-size: 0.8em;
  padding: 0.3em 0.7em;
  display: inline-block;
  border-radius: 5px;
  background-color: $light-gray;
  text-transform: uppercase;
  
  &.tag-new {
    background-color: $brand-yellow;
    color: #000;
  }

  &.tag-alert {
    background-color: $red;
    color: #000;
  }

  &.tag-safe {
    background-color: $green;
    color: #000;
  }
}


.jobs__container {
  @include display-flex;
  @include flex-direction(row);
  @include flex-wrap(wrap);
  @include justify-content(space-between);
  width: 100%;
  padding-bottom: 6em;

  @include MQ(sm) {
    width: 85%;
    margin: 0 auto;
  }
}

.job__category {
  width: 100%;
  margin-top: 3em;

  h3 {
    text-transform: capitalize;
    color: $blue;
  }
}

.job {
  display: block;
  width: 100%;
  @include MQ(md) {
    display: inline-block;
    width: percentage(1/3);
  }
  
  .job__content {
    border: 1px solid $blue;
  }
}

.jobs h1 {
  margin-top: 1em;
}

.job__category-link {
  display: inline-block;
  margin: 0.2em;
  
  a {
    display: inline-block;
    background-color: #fafafa;
    border-radius: 10px;
    text-transform: capitalize;
    transition: background-color 300ms ease;
    
    &:hover {
      background-color: $light-gray;
    }
  }

  input {
    opacity: 0;
    position: absolute;
  }

}

.jobline {
  display: block;
  width: 100%;
  transition: background-color 300ms ease;

  &:hover {
    background-color: #fafafa;
  }

  .job__company > * {
    // font-size: 1.3em;
    font-weight: 900;

  }

  .job__title, .job__company, .job__department {
    display: inline-block;
  }
  
  .job__title {
    padding: 0.5em 3em 0.5em 0;
    display: block;
    .link {
      color: $blue;
      text-decoration: underline;
      font-weight: normal;
    }

    @include MQ(sm) {
      // padding: 0 1em;
      display: inline-block;
    }
  }
  
  
  
  .job__content {
    position: relative;
    border-top: 1px solid $light-gray;
  }
  
  .job__date {
    position: absolute;
    top: 0.8em;
    right: 0.7em;
  }

  &:last-of-type {
    .job__content {
      border-bottom: 1px solid $light-gray;
    }
  }

  .tag {
    position: absolute;
    top: 2.9em;
    right: 0.8em;

    @include MQ(sm) {
      top: 0.8em;
      bottom: inherit;
      right: inherit;
      left: -4em;
    }
  }
}

.jobblock {
  display: block;
  position: relative;
  width: 100%; 
  border-radius: 5px;
  background-color: $light-gray;
  float: right;
  transition: background-color 250ms ease;
  margin: 1em 0;
  min-height: 10em;

  &:hover {
    background-color: $blue;
    color: #fff;

    .cappedLink {
      color: $light-gray !important;
    }
  }

  .tag {
    position: absolute;
    top: 1em;
    right: 1em;
  }

  .job__company > * {
    // font-size: 1.3em;
    font-weight: 900;
  }

  .job__title {
    .cappedLink {
      color: $blue;
      text-decoration: underline;
    }
  }

  .job__preview {
    margin-top: 3em;
  }
}

.job__categories {
  padding-top: 2em;
}

.job__list {
  display: inline-block;
  width: 100%;
}

.related-posts {
  width: 94%;
  max-width: 1000px;
  margin: 2em auto;

  h2 {
    color: $blue;
    font-weight: 900;
    margin-bottom: 1em;
  }
}

.jobPost {
  h1 {
    text-transform: capitalize;
    padding-bottom: 0;
  }
  
  h3 {
    padding-bottom: 0;
  }

  .jobpost__logo {
    max-height: 250px;
  }
}

.jobpost_cta {
  margin: 1em 0;
}

.jobPost__company-image {
  margin: 0 auto;
  img {
    max-width: 100%;
  }
}

.job-post {
  padding: 150px 0px 100px 0;
  margin: 0 auto;

  h1, h2, h3, h4, h5, p {
    padding: $gutter_width/2;
  }

  p, a, b {
    color: #000;
    -webkit-font-smoothing: auto;
  }

  .postHeader, .jobPost {
    margin: 0 auto;
  }
}