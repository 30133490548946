.library-modules {
  padding-top: 5rem;
}


.library-module {
  max-width: 800px;
  margin: 0 auto;
  padding: 3rem 0;

}

.lm__header {
  img {
    float: left;
    max-width: 100px;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  
}

.lm__description {
  clear: both;
}

.video-img {
  img {
    width: 100%;
  }
}

.video-cta {
  padding: 1rem 0;
}