.menu-button {
  display: inline-block;
  padding: .5rem;

  @include MQ(l) {
    display: none;
  }
}

.burger-icon {
  @include burger(25px, 3px, 5px, $black);
}

.menu-button.is-active .burger-icon {
  @include burger-to-cross;
}

.fixed-header, .default-subnav {
  .cappedLink {
    color: #000;

    .icon-wrapper {
      padding-left: 0.35rem;
    }

    .fa {
      font-size: 0.8rem;
      vertical-align: top;
      padding-top: 0.4rem;
      // transform: rotate(90deg);
      // transform-origin: center;
      // transition: transform 300ms ease;
    }

    &.active {
      border-bottom: 2px solid $light-blue;
      
      // .fa {
      //   transform: rotate(0deg)
      // }
    }
  }
}

.nav-grid {
  display: grid;
  grid-template-columns: min-content auto min-content;
  grid-template-areas:
  "logo . default-nav";
  z-index: 500;
  position: relative;
  background-color: white;
  
  @include MQ(l) {
    grid-template-columns: min-content auto auto;
  }
}

.nav-logo {
  grid-area: logo;
  align-self: center;
  padding: 1rem 0 0.25rem 0;
}

.default-nav {
  grid-area: default-nav;
  display: grid;
  align-items: center;
  
  ul {
    padding: 0;
    grid-template-columns: repeat(6, auto);
    list-style: none;
    
    &.desktop {
      display: none;
      
      @include MQ(l) {
        display: grid;
      }
    }
  }
  
  li {
    text-align: right;
    
    span:hover {
      cursor: pointer;
    }
  }
  
  a.active {
    border-bottom: 1px solid $blue;
  }
}

.default-subnav {
  display: none;
  position: fixed;
  left: 0;
  top: -6px;
  width: 100%;
  background-color: white;
  z-index: 300;
  transition: top 300ms ease;
  border-bottom: 1px solid $light-gray;

  @include MQ(l) {
    display: block;
  }
  
  &.open {
    // box-shadow: 0px 1px 15px $light-gray;
    top: 104px;

    &.promo-bar-is-open {
      top: 151px;
    }
  }
  
  ul {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(70px, max-content));
    column-gap: 3rem;
    justify-content: end;

    padding: 0;
    list-style: none;
  }

  li {
    text-align: right;
  }
}

.offcanvas {
  position: fixed;
  z-index: 250;
  right: -250px;
  top: 0;
  width: 100%;
  max-width: 250px;
  height: 100vh;
  padding: 2rem;
  background-color: $black;
  transition: right 300ms ease;
  overflow-y: scroll;

  &.active {
    right: 0;
  }

  @include MQ(l) {
    display: none;
  }

  & * {
    color: $white;
  }


  .offcanvas__content {
    position: relative;
    min-height: 100%;

    .movile_nav_logo {
      float: left;
      max-width: 44px;
    }
  }

  .close {
    width: 120px;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

.mobile-sitenav__links>li {
  text-transform: uppercase;
  padding: 0.5rem 0;

  .cappedLink {
    color: #fff;
    opacity: 1;
  }
}

.mobile-sub-menu-parent {
  border-bottom: 1px solid $light-gray;
}

.mobile-sub-menu {
  display: none;
}

.fixed-header {
  position: fixed;
  z-index: 500;
  background-color: white;
  width: 100%;
  border-bottom: 1px solid $light-gray;
  top: 38px;
  right: 0;
  transition: right 300ms ease;

  // when body has .menu-open class, change the right value to 250px
  .menu-open & {
    right: 250px;
  }

  &.promo-bar-is-open {
    top: 106px;
    
    @include MQ(md) {
      top: 82px;
    }
  }
}