.video-player {
  position: relative;
  height: 0;
  overflow: hidden;
  padding-bottom: 58.25%;
}
 
.video-player iframe {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
}

.le-date {
  display: inline-block;
}

.live-indicator {
  display: inline-block;
  
  span {
    display: none;
    padding: 0.2rem 0.5rem;
    
    &.active {
      display: block;
    }
    
    &.live-on {
      border: 1px solid $red;
      color: $red;
    }

    &.live-ended {
      border: 1px solid $brand-gray;
      color: $brand-gray;
    }
    
  }
}

.upcoming-events {
  padding-top: 3rem;

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 2rem;
  }
}

.upcoming-event {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: grid;
  align-content: end;
  height: 250px;
  max-width: 500px;
  
  .event-date {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }
}

.ue-content {
  position: relative;
  height: 115px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background-color: $blue;
  }

  &>* {
    position: relative;
    color: #fff;
  }

  h4 {
    padding: 0;
  }
}

.next-event {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: grid;
  align-content: end;
  height: 450px;
  // max-width: 500px;

  .next-event__content {
    position: relative;
    height: 250px;
    
    @include MQ(md) {
      height: 180px;
    }
    
    @include MQ(l) {
      height: 130px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.7;
      background-color: $blue;
    }

    &>* {
      position: relative;
      color: #fff;
    }

    h2 {
      padding: 0;
    }
  }

  .event-date {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }
}


// past events
.past-events__container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  
  @include MQ(sm) {
    grid-template-columns: 1fr 1fr;
  }
}