.siteheader {
  position: absolute;
  top: 2rem;
  left: 0;
  width: 100%;
  z-index: 200;

  display: grid;
  grid-template-columns: 200px auto;

  @include MQ(md) {
    top: 3rem;
  }

  &.promo-bar-is-open {
    top: 6rem;

    @include MQ(sm) {
      top: 4rem;
    }
  }
}

.mainLogo {
  padding-left: 3rem;
  display: grid;
  align-items: center;
}



.mainNav {
  display: grid;
  justify-content: end;
  position: relative;
  padding-right: 1rem;
  
  @include MQ(md) {
    padding-right: 3rem;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    text-align: center;
    // margin: 0 1em;
    padding: 1rem;
    position: relative;

    &:not(.menu-link) {
      display: none;

      @include MQ(md) {
        display: inline-block;
      }
    }

    &.menu-link {
      &:before {
        content: 'Close';
        position: absolute;
        top: 0;
        right: 0;
        height: 59px;
        width: 80px;
        padding: 1rem 0;
        font-family: 'Visby';
        text-transform: uppercase;
        color: transparent;
        z-index: -100;
        background-color: transparent;
        transition: background-color 200ms ease, color 200ms ease;

        @include MQ(md) {
          content: '';
        }
      }
      &.active {
        .cappedLink {
          opacity: 0;
          
          @include MQ(md) {
            color: #000;
            opacity: 1;
          }
        }
        a {
          border-color: #f0f0f0;
        }
        &:before {
          background-color: #f0f0f0;
          color: #000;
        }
      }
    }
  }

  a {
    border-bottom: 2px solid #fff;
  }
}

.megamenu {
  grid-column: 1 / 3;
  background-color: #f0f0f0;
  opacity: 0;
  pointer-events: none;
  display: none;
  padding: 0.5rem;
  box-shadow: 0 9px 12px rgba(0, 0, 0, 0.1);
  transition: opacity 200ms ease;
  
  &.active {
    display: block;
    opacity: 1;
    pointer-events: all;
  }

  a:not(.button) {
    display: inline-block;
    padding: 0.5rem 0;
    font-size: 1.2rem;

    @include MQ(md) {
      padding: 0.3rem 0;
      font-size: inherit;
    }
  }

  @include MQ(md) {
    padding: 2rem 4rem;
  }
}

.megamenu__content {
  
  @include MQ(md) {
    display: grid;
    grid-template-columns: auto minmax(auto, 550px);
    grid-gap: 0 4rem;
    justify-content: end;
  }
  
  @include MQ(l) {
    grid-template-columns: auto 600px;
  }
  
  .list-groups {
    
    @include MQ(md) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 175px) );
      grid-gap: 0;
      justify-content: end;
    }
    
    @include MQ(l) {
      grid-gap: 2rem;
    }
  }

  .list-group {

    @include MQ(md) {
      max-width: 175px;
    }
  }

  ul {
    list-style: none;
    padding: 0;
  }

  .social {
    grid-column: 2;
    justify-self: end;
    padding-right: 0.5rem;
  }
}

.list-group {
  padding: 0.3rem 0;

  h5 {
    color: $blue;
    padding: 0.8rem 0;
    border-top: 1px solid $brand-gray;
    font-size: 1.2rem;
    margin-bottom: 0;

    &:hover {
      cursor: pointer;

      @include MQ(md) {
        cursor: auto;
      }
    }

    @include MQ(md) {
      border: none;
      pointer-events: none;
      font-size: inherit;
    }
  }

  ul {
    display: none;
    margin: 0;
    padding-bottom: 1rem;
    @include MQ(md) {
      display: block;
    }
  }

  li {
    padding: 0.3rem 0;
  }
}

.siteheader.dark {
  .mainNav a, .cappedLink {
    color: $black;
    border-color: $black;
  }
}