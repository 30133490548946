.events {
  width: 100%;
  // background-color: $lightest-gray;
  padding: $gutter_width*3 0 0 0;

  .events-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .h1 {
    margin-bottom: 0;
  }

  .event-section {
    padding-bottom: 4rem;

  }
}

.event-cat-header {
  padding-left: 0.5rem;
}

.event-group__description {
  font-size: 1.2rem;
  max-width: 700px;
  
  a {
    text-decoration: underline;
  }
}

.event {
  width: 100%;

  @include MQ(sm) {
    width: percentage(1/2);
  }

  @include MQ(l) {
    width: percentage(1/3);
  }
}

.event-header {

  img {
    width: 100%;
  }
}

.event-content {
  position: relative;
  height: 100%;
  padding-bottom: $gutter_width*2;
  border: 1px solid lighten($brand-gray, 40%);
  background-color: #fff;
  transition: border-color 500ms ease;

  &:hover {
		border-color: $brand-yellow;
	}
}

.event-meta {
  padding: $gutter_width/2 $gutter_width/2 $gutter_width;

  h3 {
    margin-bottom: $gutter_width/2;
  }
}

.event-date, .event-time {
  color: #000;
  font-family: "Source Sans Pro", sans-serif;
}

.event-cta {
  position: absolute;
  left: $gutter_width/2;
  bottom: $gutter_width;
  text-transform: uppercase;
}

.callout {
  padding-top: 4rem;
}
