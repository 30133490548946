.health-equity {
    .slider-box {
        max-width: 800px;
    
        img {
            width: 100%;
            max-width: none;
            max-height: none;
        }
    }

}

.body-paragraph {
    display: inline-block;

    img {
        max-width: 500px;
        width: 100%;
    }
}

#equity-testimonial {
    .fa-chevron-right, .fa-chevron-left {
        color: $blue;
    }
}