.about-section {
  position: relative;
  padding: 3rem 0;
  
  @include MQ(md) {
    padding: 4rem 0;

  }

  .headline {
    margin-bottom: 2rem;
    max-width: 700px;
  }
}

.about-hero {
  color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 80% center;

  .container {
    padding-top: 8rem;
    padding-bottom: 5rem;
    
    @include MQ(md) {
      padding-bottom: 12rem;
    }
  
    h2 {
      text-align: center;
    }
  
    img {
      width: 100%;
  
      @include MQ(md) {
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.16), 0 6px 12px rgba(0, 0, 0, 0.23);
      }
    }
  }

  .headline {
    margin: 0 auto;
    position: relative;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -5%;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 150px 900px 0 0;
    border-color: transparent #00ADD0 transparent transparent;
    opacity: 1;
    z-index: 0;

    @include MQ(md) {
      bottom: 10%;
    }
  }
}

.about-story {
  padding-top: 0;

  &.angled {
    &:before {
      content: '';
      position: absolute;
      top: -2%;
      left: 0;
      width: 100%;
      height: 100%;
      background: #f0f0f0;
      transform: skewY(2deg);
      opacity: 1;
      z-index: 0;
      
      @include MQ(md) {
        height: 106%;
        top: -6%;
      }
    }
  }


  * {
    z-index: 100;
    position: relative;
  }

  .features-container {
    padding-top: 4rem;
  }

  h3 {
    color: $blue;
    font-style: italic;
    margin: 2rem 0;

    @include MQ(md) {
      width: 105%;
      margin-left: -5%;
    }
  }
}

.about-story__container {
  // margin-bottom: 5rem;
  // @include display-flex;

  // &> * {
  //   @include columnify(6)
  // }
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  
  @include MQ(md) {
    grid-template-columns: 40% auto;
    grid-gap: 2rem;
    
    &.image-right {
      grid-template-columns: auto 40%;
    }
  }

  @include MQ(l) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;

    &.image-right {
      grid-template-columns: 1fr 1fr;
    }
  }

  img {
    width: 100%;
  }
}

// .story-description {
//   img {
//     width: 100%;

//     @include MQ(md) {
//       float: right;
//       margin-left: 2rem;
//       margin-right: -4rem;
//       max-width: 400px;
//     }
//   }
// }

.story-description, .model-description {
  max-width: 700px;
  margin: 0 auto;

  p {
    margin-bottom: 1rem;
  }
}

.model {
  display: grid;
  align-content: space-between;
  grid-gap: 0.5rem;
  padding-bottom: 3rem;
}

.model__name {
  display: grid;
  grid-template-columns: 45px auto;
  grid-gap: 1rem;

  img {
    max-height: 45px;
  }
}

.model__cta a {
  border-bottom: 2px solid $blue;

  .fa {
    font-size: 1rem;
  }
}

.about-explore {
  padding-bottom: 4rem;
}

.boxes {
  margin: 0 auto;
  max-width: 1200px;

  @include MQ(md) {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
    // grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    justify-content: space-around;
  }
}

.box {
  padding: 0 1em 3rem;
  outline: none;
  
  .box-meta {
    display: grid;
    align-content: space-between;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    padding: 2em 2em 3em;
    min-height: 230px;
    position: relative;
    transition: box-shadow 350ms ease, border-color 350ms ease;

    background-color: #fafafa;

    &:hover {
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.16), 0 6px 12px rgba(0, 0, 0, 0.23);
    }

    &.em-last {
      display: grid;
      justify-content: center;
      align-items: center;
      text-align: center;

      .button {
        background-color: $red;
      }
    }

    .box-date {
      position: absolute;
      left: 2em;
      bottom: 2em;
      padding-top: 1rem;
      border-top: 2px solid $red;
    }
  }
}

.box__cta {
  text-align: center;

  a.button {
    background-color: $light-blue;

    &:hover, &:active {
      background-color: darken($light-blue, 15%);
    }
  }
}

.split-cta__container {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 900px;
  margin: 0 auto;
  overflow: hidden;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  @include MQ(md) {
    grid-template-columns: 55% 45%;

  }
}

.split__side {
  padding: 5rem 3rem;
  
}

.split__one {
  background-color: #f0f0f0;
}

.split__two {
  background-color: $blue;
  color: #fff;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    right: 0;
    top: -50px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0px 50px 400px;
    border-color: transparent transparent $blue transparent;
    opacity: 1;
    z-index: 0;

    @include MQ(md) {
      right: initial;
      left: -144px;
      top: 0;
      border-width: 0 0px 330px 144px;
    }

    // content: '';
    // position: absolute;
    // top: -2%;
    // left: 0;
    // width: 100%;
    // height: 100%;
    // background: $blue;
    // transform: skewY(2deg);
    // opacity: 1;
    // z-index: 0;
  }
}