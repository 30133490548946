$font-size-base:              1.125rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                ($font-size-base * 1.25) !default;
$font-size-sm:                ($font-size-base * .875) !default;

$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;

$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.5 !default;
$headings-line-height:        1.2 !default;

$h0-font-size:                1rem * 5 !default;
$h1-font-size:                1rem * 2.488 !default;
$h2-font-size:                1rem * 2.074 !default;
$h3-font-size:                1rem * 1.728 !default;
$h4-font-size:                1rem * 1.44 !default;
$h5-font-size:                1rem * 1.2 !default;
$h6-font-size:                1rem !default;

html {
  font-size: $font-size-base; }
body {
  // font-size: 1.5em; /* currently ems cause chrome bug misinterpreting rems on body element */
  line-height: 1.6;
  font-weight: 400;
  font-family: "Visby";
  color: #000;
  background-color: #fff;
  position: relative;

  // Mobile menu animation
  right: 0;
  transition: right 300ms ease;

  &.menu-open {
    // transform: translateX(-250px);
    // change transform to right: -250px to fix mobile menu bug
    right: 250px;
    overflow-y: hidden;
  }
}

hr {
  border: 0.5px solid $light-gray;

  &.styled-break {
    width: 20%;
    min-width: 10em;
    max-width: 300px;
    margin-left: 0;
    border: 2px solid $blue;

    &.sb-aqua {
      border-color: $aqua;
    }

    &.sb-blue {
      border-color: $blue;
    }

    &.sb-brand-yellow {
      border-color: $brand-yellow;
    }
  }
}

.wrapper {
  position: relative;
}

.locked {
  overflow: hidden;
  position: relative;
}

* {
  @include border-box;
}

.element {
  padding: $gutter_width/2;
}

.container {
  width: 94%;
  max-width: 1200px;
  margin: 0 auto;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 1rem;
  line-height: $headings-line-height;
  font-weight: normal;

  &.header-light {
    font-weight: 300;

    p {
      font-family: "Visby";
      font-weight: 300;
    }
    
    // a {
    //   @extend .link;
    // }
  }

  &.header-bold {
    font-weight: 900;
  }

  &.header-blue {
    color: $blue;
  }
}
.h0 { font-size: $h0-font-size*.8; }
h1, .h1 { font-size: $h1-font-size*.8; }
h2, .h2 { font-size: $h2-font-size*.8; }
h3, .h3 { font-size: $h3-font-size*.8; }
h4, .h4 { font-size: $h4-font-size*.8; }
h5, .h5 { font-size: $h5-font-size*.8; }
h6, .h6 { font-size: $h6-font-size*.8; }


/* Larger than phablet */
@media (min-width: 550px) {
  h0 { font-size: $h0-font-size; }
  h1, .h1 { font-size: $h1-font-size; }
  h2, .h2 { font-size: $h2-font-size; }
  h3, .h3 { font-size: $h3-font-size; }
  h4, .h4 { font-size: $h4-font-size; }
  h5, .h5 { font-size: $h5-font-size; }
  h6, .h6 { font-size: $h6-font-size; }
}

.headline {
  position: relative;

  &.headline-centered {
    margin: 0 auto;
    text-align: center;

    &.over-video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &.headline-constrained {
    width: 94%;
    max-width: 610px;
  }

  h1 {
    margin-bottom: 1rem;
  }

  .h1 {
    font-family: "Visby";
  }

  .color-light {
    color: #fff;
  }
}


p {
  margin: 0px;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
}

.text-lg {
  font-size: $font-size-lg;
}

i.fa {
  font-size: 1.5rem;
}

a, .link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  outline: none;

  &.disabled {
    pointer-events: none;
    cursor: default;
    color: $brand-gray;
  }

  .styled-text & {
    text-decoration: underline;
    color: $blue;

    &:hover {
      color: darken($blue, 12%);
    }
  }
}

.link-component {
  display: inline-block;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.16), 0 6px 12px rgba(0, 0, 0, 0.23);
  display: flex;
  max-width: 550px;
  margin: 0 auto;
}
.link-component__img {
  flex: 2;
  font-size: 0;

  img {
    width: 100%;
  }

  @include MQ(md) {
    flex: 1;
  }
}

.link-component__copy {
  flex: 3;
  padding: 0.5rem 1rem;

  span {
    font-size: $font-size-base;
    color: #000;
  }

  @include MQ(md) {
    flex: 2;
    padding: 2rem
  }
}

.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  display: inline !important;
  height: inherit !important;
  padding: 10px 15px !important;
  color: #555;
  text-align: center !important;
  // font-size: 11px;
  font-weight: 300 !important;
  line-height: 38px !important;
  letter-spacing: .1rem !important;
  text-transform: uppercase !important;
  text-decoration: none !important;
  white-space: nowrap !important;
  background-color: transparent;
  border-radius: 0 !important;
  border: 1px solid #bbb;
  cursor: pointer !important;
  box-sizing: border-box !important;
  transition: background-color 350ms ease, border-color 350ms ease, opacity 350ms ease !important;
  vertical-align: baseline !important;

  @include MQ(md) {
    padding: 10px 30px !important;
  }
}

.button:hover,
button:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
.button:focus,
button:focus,
input[type="submit"]:focus,
input[type="reset"]:focus,
input[type="button"]:focus {
  color: #333;
  border-color: #888;
  outline: 0;
}

.button.button-primary,
button.button-primary,
input[type="submit"].button-primary,
input[type="reset"].button-primary,
input[type="button"].button-primary {
  color: #FFF !important;
  background-color: $blue !important;
  border-color: $blue !important;
}

.button.button-primary:hover,
button.button-primary:hover,
input[type="submit"].button-primary:hover,
input[type="reset"].button-primary:hover,
input[type="button"].button-primary:hover,
.button.button-primary:focus,
button.button-primary:focus,
input[type="submit"].button-primary:focus,
input[type="reset"].button-primary:focus,
input[type="button"].button-primary:focus {
  color: #FFF !important;
  background-color: darken($blue, 5%) !important;
  border-color: darken($blue, 5%) !important;
}

.button.button-ghost-primary,
button.button-ghost-primary,
input[type="submit"].button-ghost-primary,
input[type="reset"].button-ghost-primary,
input[type="button"].button-ghost-primary {
  color: $blue !important;
  background-color: transparent !important;
  border-color: $blue !important;
  opacity: 0.7 !important;
}

.button.button-ghost-primary:hover,
button.button-ghost-primary:hover,
input[type="submit"].button-ghost-primary:hover,
input[type="reset"].button-ghost-primary:hover,
input[type="button"].button-ghost-primary:hover,
.button.button-ghost-primary:focus,
button.button-ghost-primary:focus,
input[type="submit"].button-ghost-primary:focus,
input[type="reset"].button-ghost-primary:focus,
input[type="button"].button-ghost-primary:focus {
  color: $blue !important;
  background-color: transparent !important;
  border-color: darken($blue, 5%) !important;
  opacity: 1 !important;
}

.button.button-ghost-white,
button.button-ghost-white,
input[type="submit"].button-ghost-white,
input[type="reset"].button-ghost-white,
input[type="button"].button-ghost-white {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
  opacity: 0.7;
}

.button.button-ghost-white:hover,
button.button-ghost-white:hover,
input[type="submit"].button-ghost-white:hover,
input[type="reset"].button-ghost-white:hover,
input[type="button"].button-ghost-white:hover,
.button.button-ghost-white:focus,
button.button-ghost-white:focus,
input[type="submit"].button-ghost-white:focus,
input[type="reset"].button-ghost-white:focus,
input[type="button"].button-ghost-white:focus {
  color: #fff;
  background-color: transparent;
  border-color: darken(#fff, 5%);
  opacity: 1;
}

.button.button-aqua,
button.button-aqua,
input[type="submit"].button-aqua,
input[type="reset"].button-aqua,
input[type="button"].button-aqua {
  color: #FFF !important;
  background-color: $aqua !important;
  border-color: $aqua !important;
}

.button.button-aqua:hover,
button.button-aqua:hover,
input[type="submit"].button-aqua:hover,
input[type="reset"].button-aqua:hover,
input[type="button"].button-aqua:hover,
.button.button-aqua:focus,
button.button-aqua:focus,
input[type="submit"].button-aqua:focus,
input[type="reset"].button-aqua:focus,
input[type="button"].button-aqua:focus {
  color: #FFF !important;
  background-color: darken($aqua, 5%) !important;
  border-color: darken($aqua, 5%) !important;
}

.button.button-green,
button.button-green,
input[type="submit"].button-green,
input[type="reset"].button-green,
input[type="button"].button-green {
  color: $black !important;
  background-color: $green !important;
  border-color: $green !important;
}

.button.button-green:hover,
button.button-green:hover,
input[type="submit"].button-green:hover,
input[type="reset"].button-green:hover,
input[type="button"].button-green:hover,
.button.button-green:focus,
button.button-green:focus,
input[type="submit"].button-green:focus,
input[type="reset"].button-green:focus,
input[type="button"].button-green:focus {
  color: $black !important;
  background-color: darken($green, 5%) !important;
  border-color: darken($green, 5%) !important;
}

.button.button-ghost-aqua,
button.button-ghost-aqua,
input[type="submit"].button-ghost-aqua,
input[type="reset"].button-ghost-aqua,
input[type="button"].button-ghost-aqua {
  color: $aqua !important;
  background-color: transparent !important;
  border-color: $aqua !important;
  opacity: 0.7 !important;
}

.button.button-ghost-aqua:hover,
button.button-ghost-aqua:hover,
input[type="submit"].button-ghost-aqua:hover,
input[type="reset"].button-ghost-aqua:hover,
input[type="button"].button-ghost-aqua:hover,
.button.button-ghost-aqua:focus,
button.button-ghost-aqua:focus,
input[type="submit"].button-ghost-aqua:focus,
input[type="reset"].button-ghost-aqua:focus,
input[type="button"].button-ghost-aqua:focus {
  color: $aqua !important;
  background-color: transparent !important;
  border-color: darken($aqua, 5%) !important;
  opacity: 1 !important;
}

.button.button-red,
button.button-red,
input[type="submit"].button-red,
input[type="reset"].button-red,
input[type="button"].button-red {
  color: #FFF;
  background-color: $red;
  border-color: $red;
}

.button.button-red:hover,
button.button-red:hover,
input[type="submit"].button-red:hover,
input[type="reset"].button-red:hover,
input[type="button"].button-red:hover,
.button.button-red:focus,
button.button-red:focus,
input[type="submit"].button-red:focus,
input[type="reset"].button-red:focus,
input[type="button"].button-red:focus {
  color: #FFF;
  background-color: darken($red, 5%);
  border-color: darken($red, 5%);
}

.button.button-yellow,
button.button-yellow,
input[type="submit"].button-yellow,
input[type="reset"].button-yellow,
input[type="button"].button-yellow {
  color: #000;
  background-color: $brand-yellow;
  border-color: $brand-yellow;
}

.button.button-yellow:hover,
button.button-yellow:hover,
input[type="submit"].button-yellow:hover,
input[type="reset"].button-yellow:hover,
input[type="button"].button-yellow:hover,
.button.button-yellow:focus,
button.button-yellow:focus,
input[type="submit"].button-yellow:focus,
input[type="reset"].button-yellow:focus,
input[type="button"].button-yellow:focus {
  color: #000;
  background-color: darken($brand-yellow, 5%);
  border-color: darken($brand-yellow, 5%);
}

.button.button-light-blue,
button.button-light-blue,
input[type="submit"].button-light-blue,
input[type="reset"].button-light-blue,
input[type="button"].button-light-blue {
  color: #FFF;
  background-color: $light-blue;
  border-color: $light-blue;
}

.button.button-light-blue:hover,
button.button-light-blue:hover,
input[type="submit"].button-light-blue:hover,
input[type="reset"].button-light-blue:hover,
input[type="button"].button-light-blue:hover,
.button.button-light-blue:focus,
button.button-light-blue:focus,
input[type="submit"].button-light-blue:focus,
input[type="reset"].button-light-blue:focus,
input[type="button"].button-light-blue:focus {
  color: #FFF;
  background-color: darken($light-blue, 5%);
  border-color: darken($light-blue, 5%);
}

.button.button-black,
button.button-black,
input[type="submit"].button-black,
input[type="reset"].button-black,
input[type="button"].button-black {
  color: #FFF;
  background-color: $black;
  border-color: $black;
}

.button.button-black:hover,
button.button-black:hover,
input[type="submit"].button-black:hover,
input[type="reset"].button-black:hover,
input[type="button"].button-black:hover,
.button.button-black:focus,
button.button-black:focus,
input[type="submit"].button-black:focus,
input[type="reset"].button-black:focus,
input[type="button"].button-black:focus {
  color: #FFF;
  background-color: darken($black, 5%);
  border-color: darken($black, 5%);
}

.button.button-ghost-aqua,
button.button-ghost-aqua,
input[type="submit"].button-ghost-aqua,
input[type="reset"].button-ghost-aqua,
input[type="button"].button-ghost-aqua {
  color: $aqua !important;
  background-color: transparent !important;
  border-color: $aqua !important;
  opacity: 0.7 !important;
}

.button.button-ghost-black:hover,
button.button-ghost-black:hover,
input[type="submit"].button-ghost-black:hover,
input[type="reset"].button-ghost-black:hover,
input[type="button"].button-ghost-black:hover,
.button.button-ghost-black:focus,
button.button-ghost-black:focus,
input[type="submit"].button-ghost-black:focus,
input[type="reset"].button-ghost-black:focus,
input[type="button"].button-ghost-black:focus {
  color: $black !important;
  background-color: transparent !important;
  border-color: darken($black, 5%) !important;
  opacity: 1 !important;
}

.button.button-gray,
button.button-gray,
input[type="submit"].button-gray,
input[type="reset"].button-gray,
input[type="button"].button-gray {
  color: #000;
  background-color: $lightest-gray;
  border-color: $lightest-gray;
}

.button.button-gray:hover,
button.button-gray:hover,
input[type="submit"].button-gray:hover,
input[type="reset"].button-gray:hover,
input[type="button"].button-gray:hover,
.button.button-gray:focus,
button.button-gray:focus,
input[type="submit"].button-gray:focus,
input[type="reset"].button-gray:focus,
input[type="button"].button-gray:focus {
  color: #000;
  background-color: darken($lightest-gray, 5%);
  border-color: darken($lightest-gray, 5%);
}

%cappedLink {
  @include transition(all 200ms ease);
  opacity: .75;
  // font-size: .8rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: "Visby", sans-serif;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:hover {
    opacity: 1;
  }

  &.active {
    opacity: 1;
  }

  &.dark {
    color: $black;

  }
}

.cappedLink {
  @extend %cappedLink;
}

.icon {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(33%);
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  &.icon-lg {
    width: 50px;
    height: 50px;
  }
  
  &.icon-md {
    width: 30px;
    height: 30px;
  }
  
  &.icon-sm {
    width: 16px;
    height: 16px;
  }
}

.arrow-left {
  &:before {
    background-image: url("/assets/images/ic_right_arrow_white.svg");
    transform: rotate(180deg);
  }
}

.arrow-up {
  &:before {
    background-image: url("/assets/images/ic_right_arrow_white.svg");
    transform: rotate(45deg);
  }
}

.arrow-down {
  &:before {
    background-image: url("/assets/images/ic_right_arrow_white.svg");
    transform: rotate(-45deg);
  }
}

a.with-arrow {
  width: 150%;
  padding-right: 50%;
  display: inline-block;
  position: relative;
}

.cssicon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -150%);
  width: 20px;
  height: 15px;
  cursor: pointer;
}

.arrow {
  position: absolute;
  top: 25px;
  width: 90%;
  height: 3px;
  background-color: $black;
  // box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
  animation: arrow 700ms linear infinite;
  border-radius: 3px;
}

.arrow::after {
  content: '';
  position: absolute;
  width: 60%;
  height: 3px;
  top: -3px;
  right: -3px;
  background-color: $black;
  transform: rotate(45deg);
  border-radius: 3px;
}

.arrow::before {
  content: '';
  position: absolute;
  width: 60%;
  height: 3px;
  top: 3px;
  right: -3px;
  background-color: $black;
  // box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
  transform: rotate(-45deg);
  border-radius: 3px;
}

// art
.art-circle {
  position: relative;

  &:before {
    position: absolute;
    content: '';
    bottom: 2rem;
    right: 0;
    width: 200px;
    height: 200px;
    background-color: $brand-yellow;
    // background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='3' height='3'%3E%3Ccircle cx='2.5' cy='2.5' r='0.9' fill='%2300ADD0'/%3E%3C/svg%3E") repeat bottom right;
    // background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='3' height='3'%3E%3Ccircle cx='2.5' cy='2.5' r='0.9' fill='%23FECF5D'/%3E%3C/svg%3E") repeat bottom right;
    border-radius: 100%;
    z-index: 10;
    
    @include MQ(md) {
      right: -1rem;
      width: 300px;
      height: 300px;
    }
  }
}

.art-square {
  position: relative;

  &:after {
    position: absolute;
    content: '';
    top: -1rem;
    left: 0;
    height: 90%;
    width: 100%;
    background-color: $light-blue;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='3' height='3'%3E%3Ccircle cx='2.5' cy='2.5' r='0.9' fill='%2300ADD0'/%3E%3C/svg%3E") repeat bottom right;
    
    @include MQ(md) {
      left: 3rem;
      width: 96%;
      height: 80%;
    }
  }
  
  &.art-inverted {
    &::after {
      background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='3' height='3'%3E%3Ccircle cx='2.5' cy='2.5' r='0.9' fill='%232c2e37'/%3E%3C/svg%3E") repeat bottom right;
    }
  }
}

.art-triangle {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -10px;
    right: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 200px 200px 0;
    border-color: transparent $brand-yellow transparent transparent;
    opacity: 1;
    z-index: 0;
    transform: rotate(-5deg);
    
    @include MQ(md) {
      border-width: 0 400px 400px 0;
      
    }
  }
  
  &.art-inverted {
    &:before {
      border-color: transparent $light-blue transparent transparent;

    }
  }
}

.superheader {
  border-bottom: 2px dotted $brand-yellow;
}

.ta-center {
  text-align: center !important;
}

.ta-center-xs {
  @include MQ(xs) {
    text-align: center !important;
  }
}

.ta-center-sm {
  @include MQ(sm) {
    text-align: center !important;
  }  
}

.ta-center-md {
  @include MQ(md) {
    text-align: center !important;
  }
}

.ta-center-lg {
  @include MQ(lg) {
    text-align: center !important;
  }
}

.ta-left {
  text-align: left !important;
}

.ta-left-xs {
  @include MQ(xs) {
    text-align: left !important;
  }
}

.ta-left-sm {
  @include MQ(sm) {
    text-align: left !important;
  }
}

.ta-left-md {
  @include MQ(md) {
    text-align: left !important;
  }
}

.ta-left-lg {
  @include MQ(lg) {
    text-align: left !important;
  }
}

.ta-right {
  text-align: right !important;
}

.ta-right-xs {
  @include MQ(xs) {
    text-align: right !important;
  }
}

.ta-right-sm {
  @include MQ(sm) {
    text-align: right !important;
  }
}

.ta-right-md {
  @include MQ(md) {
    text-align: right !important;
  }
}

.ta-right-lg {
  @include MQ(lg) {
    text-align: right !important;
  }
}

.ta-left {
  text-align: left !important;
}

.ul-reset {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

li, td {
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
}

%container {
  max-width: 1440px;
  width: 94%;
  display: block;
  margin: 0 auto;
}

div.wrap {
  @extend %container;
  overflow: hidden;
}

.flex {
  @include display-flex;
}

.hidden {
  display: none;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mobile-hidden {
  display: none !important;
  @include MQ(md) {
    display: initial !important;
  }
}

.mobile-show {
  @include MQ(md) {
    display: none !important;
  }
}


// new loader with spinner
.loader-container {
  background: transparent;
  display: none;
}
.loader-container.active {
  display: block;
  background: #fff;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 9999;
}
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
}
.loader {
  background: inherit;
  color: $brand-gray;
  font-size: 11px;
  text-indent: -99999em;
  margin: 55px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before,
.loader:after {
  background: inherit;
  position: absolute;
  content: '';
}
.loader:before {
  width: 5.2em;
  height: 10.2em;
  // background: #fff;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.2em 5.1em;
  transform-origin: 5.2em 5.1em;
  -webkit-animation: load2 2s infinite ease 1.5s;
  animation: load2 2s infinite ease 1.5s;
}
.loader:after {
  width: 5.2em;
  height: 10.2em;
  // background: #fff;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 5.1em;
  -webkit-transform-origin: 0px 5.1em;
  transform-origin: 0px 5.1em;
  -webkit-animation: load2 2s infinite ease;
  animation: load2 2s infinite ease;
}
@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.scrollable {
  overflow: scroll;
}

// PROMO BAR
.promo-bar {
  display: none;
  position: fixed;
  width: 100%;
  // opacity: 0;
  // height: 0px;
  background: $brand-yellow;
  text-align: center;
  transition: height 500ms ease;
  z-index: 500;

  &.is-open {
    display: block;
    // height: auto;
    // opacity: 1;
  }

  p a {
    text-decoration: underline;
  }

  &.pb-fixed {
    position: fixed;
  }
}

.promo-bar__text {
  display: inline-block;
  width: 85%;
  margin: 8px auto;

  @include MQ(md) {
    width: 80%;
  }
}

.promo-bar__close {
  position: absolute;
  top: 3px;
  right: 0;
}


// basic content sections for new page styles
.page-section {
  padding: 2rem 0;
  position: relative;

  @include MQ(md) {
    padding: 4rem 0;
  }

  .headline {
    // text-align: center;
    margin-bottom: 2rem;
    z-index: 100;

    & > {
      overflow: auto;
    }

    h4 {
      text-transform: none;
    }

  }

  img:not(.program-icon) {
    width: 100%;
  }
}

.page-hero {
  opacity: 1;
  z-index: 50;
  background-position: center;
  background-size: cover;

  padding-top: 15rem;
  padding-bottom: 7rem;
  border-bottom: 1px solid transparent;
  @include MQ(md) {
    padding-top: 16rem;
  }

  .headline h1 {
    margin-bottom: 2rem;
  }

  h4 {
    text-transform: none;
  }

  .button {
    width: 100%;
    max-width: 200px;
  }

  &.dark-bg *, &.blue-bg *, &.aqua-bg * {
    color: #fff;
  }

  &.has-video {
    padding: 0;
    overflow: hidden;
    max-height: 600px;

    @include MQ(md) {
      max-height: 800px;
    }
  }

  video {
    min-width: 100%;
  }
}

.lightest-gray-bg {
  background-color: $lightest-gray;
}

.blue-bg {
  background-color: $blue;

  > * {
    color: #fff;
  }
}

.aqua-bg {
  background-color: $aqua;
}

.white-bg {
  background-color: #fff;
}

.hero-angled {
  
  -webkit-clip-path: polygon(100% 0, 100% 90%, 0% 100%, 0 0%);
  clip-path: polygon(100% 0, 100% 90%, 0% 100%, 0 0%);

  &.odd {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%); 
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);

  }
}

.diversity-hero {
  overflow: hidden;
  // overflow-y: visible;

  // &:before {
  //   content: '';
  //   position: absolute;
  //   top: -20%;
  //   left: -10%;
  //   width: 120%;
  //   height: 120%;
  //   background: $black;
  //   opacity: 1;
  //   z-index: 0;
  //   transform: skew(-2deg);
  // }

  &:before {
    content: '';
    position: absolute;
    top: -20%;
    left: 0;
    width: 100%;
    height: 113%;
    background: $black;
    transform: skewY(-2deg);
    opacity: 1;
    z-index: 0;
    
    @include MQ(md) {
      top: -25%;
      height: 120%;
    }
  }
}

.img-gradient {
  position: relative;
  min-height: 332px;
  height: 100%;
  width: 100%;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(transparent, $black), url('/uploads/matter-logo-colors.svg');
}

.angled-top {
  > * {
    z-index: 10;
    position: relative;
  }

  &:before {
    content: '';
    position: absolute;
    top: -2rem;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    // color: #fff;
    transform: skewY(2deg);
    opacity: 1;
    z-index: 0;
  }
}


.iframe-container {
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%;
  padding-top: 35px;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.mc-field-group {
  padding-bottom: 2rem !important;
}

.mc-field-group.half {
  display: inline-block;
  width: 48% !important;
}

div.mce_inline_error {
  position: absolute;
  bottom: 0;
  margin-bottom: 0 !important;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.img_container {
  max-width: 800px;
  margin: 0 auto;
  
  &.img-small {
    max-width: 500px;
  }

  &.img-large {
    max-width: none;
  }

  img {
    width: 100%;
  }
}

.notice-red {
  color: $red;
}