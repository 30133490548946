@font-face {
    font-family: 'Visby';
    src: url('/assets/styles/webfonts/visby-cf-light.eot'); /* IE9 Compat Modes */
    src: url('/assets/styles/webfonts/visby-cf-light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/assets/styles/webfonts/visby-cf-light.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/styles/webfonts/visby-cf-light.woff') format('woff'), /* Pretty Modern Browsers */
         url('/assets/styles/webfonts/visby-cf-light.ttf')  format('truetype'), /* Safari, Android, iOS */
         url('/assets/styles/webfonts/visby-cf-light.svg#svgFontName') format('svg'); /* Legacy iOS */
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Visby';
    src: url('/assets/styles/webfonts/visby-cf-demi-bold.eot'); /* IE9 Compat Modes */
    src: url('/assets/styles/webfonts/visby-cf-demi-bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/assets/styles/webfonts/visby-cf-demi-bold.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/styles/webfonts/visby-cf-demi-bold.woff') format('woff'), /* Pretty Modern Browsers */
         url('/assets/styles/webfonts/visby-cf-demi-bold.ttf')  format('truetype'), /* Safari, Android, iOS */
         url('/assets/styles/webfonts/visby-cf-demi-bold.svg#svgFontName') format('svg'); /* Legacy iOS */
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Visby';
    src: url('/assets/styles/webfonts/visby-cf-bold.eot'); /* IE9 Compat Modes */
    src: url('/assets/styles/webfonts/visby-cf-bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/assets/styles/webfonts/visby-cf-bold.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/styles/webfonts/visby-cf-bold.woff') format('woff'), /* Pretty Modern Browsers */
         url('/assets/styles/webfonts/visby-cf-bold.ttf')  format('truetype'), /* Safari, Android, iOS */
         url('/assets/styles/webfonts/visby-cf-bold.svg#svgFontName') format('svg'); /* Legacy iOS */
    font-weight: 700;
    font-style: normal;
}
