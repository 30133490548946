.challenges {

  .container {
    margin-top: 4rem;
  }
}

.upcoming-challenges {
  padding-bottom: 5rem;
}

.challenges__container {
  margin: 4rem 0;

  hr {
    margin-bottom: 4rem;
    border: 0.5px solid $light-gray;
  }

  &.past-challenges__container.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    grid-gap: 3rem;
  }
}

#no-upcoming-challenges-header {
  display: none;
}


.challenge {
  margin-bottom: 4rem;
  
  @include MQ(md) {
    display: grid;
    grid-template-columns: 300px auto;
    grid-gap: 2rem;
  }
}

.challenge__image {
  img {
    width: 100%;
  }
}

.challenge__title {
  margin-bottom: 2rem;
  
  h4 {
    color: $blue;
  }

  h5 {
    color: #000;
  }
}

.challenge__dates {
  margin-bottom: 1rem;
}

.past-challenge {
  padding: 0 1rem;
}


// challenge post view
.challenge-post-head {
  font-size: 0;

  .postHero, .postHero__mobile {
    width: 100%;
    max-width: initial;
    margin-bottom: 0;
  }
  
  .challenge__head {
    font-size: initial;
    width: 100%;
    padding: 2rem;
    background: $blue;
    color: #fff;

    hr {
      margin: 2rem 0;
    }
  
    .challenge-cta__container {
      background: transparent;
    }
  }
  
  .challenge__statement {
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
    text-wrap: balance;
    text-wrap: pretty;
  }
  
  .triangle__container {
    position: relative;
    width: 100%;
  }
  
  .triangle {
    clip-path: polygon(100% 0, 0 0, 50% 18%);
    position: absolute;
    top: -1px;
    left: 0;
    height: 300px;
    width: 100%;
    background-color: $blue;
  }
  
  .headline, .apply-button {
    text-align: center;
  }

}


.challenge-post {
  // padding: 70px 0px 50px 0;

  .postContent {
    padding-top: 5rem;
  }

  .case-studies {
    max-width: 800px;
    width: 80%;
    margin: 0 auto;

    .card__content::before {
      background-color: $blue;
    }
  }
  
  .module {
    margin-bottom: 2rem;

    img {
      margin: 0;

      &.module__image {
        // width: 100%;
        margin: 0 auto;
      }
    }

    @include MQ(sm) {
      &.half-width {
        display: inline-block;
        width: 45%;
        vertical-align: top;

        & + .half-width {
          margin-left: 3rem;
        }
      }
    }
  }

  hr {
    margin: 1rem auto;
    max-width: 900px;
  }
}

.half-width .module__headline {
  min-height: 100px;
}

.partnerLogos__container {

  @include MQ(sm) {
    display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    justify-content: center;
    grid-auto-flow: column;
    grid-gap: 4rem;
  }
  img {
    padding: 4rem;
    max-width: 200px;
  }
  
  @include MQ(md) {
    img {
      padding: 2rem 0;
    }
  }
}

.challenge-headline, .challenge-post h2 {
  color: $blue;
}

// .challenge-cta {
//   padding: 3rem 0;
// }

.challenge-cta__container {
  background-color: #C9CAC891;
  margin: 0 auto;
  text-align: center;
  padding: 1rem;
  
  @include MQ(sm) {
    width: 80%;
  }

  .button {
    margin: 1rem 0;
  }
}