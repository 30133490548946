.programs {
  @include display-flex;
  @include flex-direction(row);
  @include flex-wrap(wrap);
  @include justify-content(space-between);
  width: 100%;
}

div.program {
  position: relative;

  @include MQ(md) {
    min-height: 300px;
  }
}

div.program_content {
  padding: $gutter_width;
  border: 2px solid $blue;
  height: 100%;
}

div.program_copy {

  p, a, b {
    font-family: "Visby";
    font-weight: 300;
  }

  a.learn-more {
    color: $blue;
    @include display-flex;
    @include align-items(center);
    padding: $gutter_width/2 0px;
    font-family: "Visby";
  }

  a.light {
    color: #fff;
  }

  img {
    padding-right: $gutter_width/4;
  }
}

div.program-half_width {

  @include MQ(md) {
    width: percentage(1/2);
  }
}

div.program-full_width {
  display: block;
  width: 100%;
  min-height: 400px;

  div.program_content {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.4;
      z-index: 0;
    }
  }

  div.program_copy {
    position: relative;
    margin: 0 auto;
    @include MQ(md) {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 50%;
    }
  }

}

div.program_label {
  position: relative;
  display: table;
  color: #005C97;
  letter-spacing: 1px;
  text-transform: uppercase;
  z-index: 100;

  img {
    margin-right: $gutter_width/2;
  }

  span {
    display: table-cell;
    vertical-align: middle;
  }
}


// =============
// New program styles

.section_label {
  color: #fff;
}

.program-header {
  max-width: 1000px;
  margin-top: 9rem;
  padding-top: 50%;
  padding-bottom: 0;
  background-repeat: no-repeat;
  position: relative;
  background-position: top center;
  background-size: contain;
  
  @include MQ(md) {
    margin-top: 12rem;
    padding-top: 4rem;
    padding-bottom: 8rem;
    background-position: center right;
    background-size: cover;
  }

  img {
    width: 100%;
  }

  .program-header__content {
    display: inline-block;
    width: 100%;
    background: #fff;
    padding: 2rem 0;
    
    @include MQ(md) {
      padding: 2rem;
      width: auto;
    }
  }
}

.program-description {
  max-width: 800px;
  padding-top: 2rem;
  padding-bottom: 4rem;

  img {
    padding: 1rem 0;
    max-width: 100%;
  }

  p {
    padding-bottom: 1rem;
  }
}

.program-case-study {
  max-width: 1000px;
  padding-bottom: 5rem;
  border-top: 4px solid $blue;

  .cs-header {
    float: right;
    text-align: left;
    color: #fff;
    background: $blue;
    padding: 0.5rem 2rem;
    margin-left: 2rem;
    margin-bottom: 2rem;
    max-width: 500px;
  }

  .cs-body {
    padding-top: 2rem;

    img {
      max-width: 100%;
    }
    
    p {
      padding-bottom: 2rem;
    }
  }
}

.additional-program-case-studies {
  max-width: 1000px;
  margin-bottom: 5rem;
  padding: 3rem;
  background-color: lightgray;

  .cs-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 4rem;

    @include MQ(md) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

}
.acs {
  position: relative;

  h4 {
    min-height: 60px;
  }

  .button {
    align-self: end;
  }

  @include MQ(md) {
    &:after {
      position: absolute;
      content: '';
      height: 80%;
      width: 1px;
      border-right: 1px dashed $black;
      top: 10%;
      right: -2rem;
    }
  
    &:last-of-type:after {
      border-right: none;
    }

  }
}

.acs__body {
  height: calc(100% - (60px + 1rem));
  display: grid;
  grid-gap: 1rem;
}

.acs__body, .cs-body {
  p a {
    color: $blue;
    text-decoration: underline;
    font-weight: normal;
  }
}

.program-cta {
  position: relative;
  padding: 3rem 0;

  &:before {
    content: '';
    position: absolute;
    top: -2rem;
    left: 0;
    width: 100%;
    height: 130%;
    background: $blue;
    color: #fff;
    transform: skewY(-2deg);
    opacity: 1;
    z-index: -10;
  }

  * {
    z-index: 500;
    color: #fff;
  }
}

.partner-programs-hero {
  &:before {
    content: '';
    position: absolute;
    top: -20%;
    left: 0;
    width: 100%;
    height: 115%;
    background: $aqua;
    transform: skewY(5deg);
    opacity: 1;
    z-index: 50;
    
    * {
      color: #fff;
    }
  }
}

.member-programs-hero {
  &:before {
    content: '';
    position: absolute;
    top: -20%;
    left: 0;
    width: 100%;
    height: 115%;
    background: $blue;
    transform: skewY(5deg);
    opacity: 1;
    z-index: 50;
    
    * {
      color: #fff;
    }
  }
}

// .value-props {
//   padding-top: 5rem;
// }

.vp {
  padding: 4rem 0 2rem;
  // border-bottom: 1px solid $brand-gray;

  &:last-of-type {
    border-bottom: none;
  }
}

.value_prop_description {
  max-width: 800px;
  padding-bottom: 3rem;
}

.programs_list {
  position: relative;
  width: 100%;
  padding: 2rem 1rem;
  background-color: $black;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  
  @include MQ(md) {
    padding: 2rem 3rem;
  }

  // use psuedo element to create a transparent blue overlay
  &:before:not(.bg_light-gray) {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $blue;
    opacity: 0.3;
    z-index: 0;
  }

  .section_label {
    position: relative;
    z-index: 100;
    padding-bottom: 1rem;
  }
}

.program__description {
  font-weight: 300;

  a:not(.button), a:visited:not(.button) {
    color: $blue;
    text-decoration: underline;
    font-weight: normal;
  }
}

.program__icon img {
  max-width: 75px;
}


// /programs
.programs-hero {
  background-color: $lightest-gray;
}
.split-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 400px));
  grid-gap: 4rem;
  justify-content: space-evenly;
  text-align: center;
  
  &.narrow {
    grid-template-columns: repeat(auto-fit, minmax(100px, 250px));
    grid-gap: 0;
    justify-content: center;
  }
}

.side-description {
  padding-bottom: 2rem;
}

.programs-split-cta {
  padding: 8rem 0;
}